import { Avatar, Modal, Image } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useHistoryDonation } from './hooks';
import { TopDonation, NoData } from 'styles/image';

const HistoryDonationModal = ({ roomID, isModalOpen, onCloseModal }: any) => {
  const { donateHistoryData } = useHistoryDonation({ roomID });

  return (
    <ModalWrapper
      donateHistoryDataLength={donateHistoryData.length}
      open={isModalOpen}
      onCancel={onCloseModal}
      footer={null}
    >
      <div className="chat-container view-container">
        <div className="title-wrap">
          <img src={TopDonation} alt="" />
          <p>History donation</p>
        </div>
        <div className="view-box">
          {donateHistoryData.length > 0 ? (
            donateHistoryData.map((item, i) => (
              <div key={i} className={`view-box-col ${i === 0 && 'top'}`}>
                <div className="user-item">
                  <div className="d-box">
                    <p className="user-index">
                      {i < 9 ? '0' + (i + 1) : i + 1}
                    </p>

                    <Avatar
                      src={
                        item.sender.avatar_url !== null &&
                        item.sender.avatar_url
                      }
                      shape="square"
                      style={{ minWidth: '32px', minHeight: '32px' }}
                    >
                      {item.sender.avatar_url === null &&
                        item.sender.username.charAt(0).toLocaleUpperCase()}
                    </Avatar>

                    <p className="user-name ellipsis-1">
                      {item.sender.username}
                    </p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <p className="user-point">{item.total}</p>
                    <p className="user-point-text">
                      {item.total > 1 ? 'Points' : 'Point'}
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="nodata">
              <Image
                width={335}
                height={247}
                preview={false}
                src={NoData}
                style={{
                  objectFit: 'cover',
                }}
                alt=""
              />

              <p
                style={{
                  color: '#D1D5DB',
                  fontSize: '24px',
                  fontWeight: 'bold',
                  marginTop: '15px',
                }}
              >
                No history donate!
              </p>
            </div>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default HistoryDonationModal;

const ModalWrapper = styled(Modal)<{
  donateHistoryDataLength: number;
}>`
  .ant-modal-close {
    display: none;
  }

  .view-box {
    height: calc(
      100vh - 248px
    ); // fallback for browsers not supporting dvh units
    max-height: calc(
      100vh - 248px
    ); // fallback for browsers not supporting dvh units
    height: calc(100dvh - 248px);
    max-height: calc(100dvh - 248px);
    border-radius: 10px;
    border: 1px solid #494443;
    background: #363131;
    padding: 10px;

    overflow-y: scroll;
    overflow-x: hidden;
    ::-webkit-scrollbar-track {
      background-color: #363131;
    }

    ::-webkit-scrollbar {
      width: 2px;
      background-color: #363131;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #d9d9d9;
      border: 1px solid #d9d9d9;
    }
  }

  .title-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      color: #fff;
      margin-left: 5px;
    }
    img {
      filter: brightness(0) invert(1) !important;
    }
  }

  .ant-modal-content {
    padding: 12px 10px;
    background-color: #231f1f;
  }

  .d-box {
    display: flex;
    gap: 14px;
    align-items: center;

    .ant-badge {
      width: 48px;
      height: 48px;
    }

    p:first-child {
      width: 20px;
    }

    p:last-child {
      font-weight: 700;
    }
  }

  .view-box-col:nth-last-child(-n + 2) {
    .d-box {
      border-bottom: none;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }

  .view-box-col {
    &:not(:last-child) {
      border-bottom: 1px solid #5f5858;
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
  }

  .view-box-col.top {
    .user-index,
    .user-name {
      font-weight: 700;
      color: #ff347d;
    }
  }

  .user-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .user-index {
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      color: #5f5858;
    }

    .ant-avatar {
      background: #ff347d;
    }

    .user-name {
      max-width: 50%;
      color: #928c8c;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.14px;
    }

    .user-point {
      color: #ff347d;
      text-align: right;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.14px;
    }

    .user-point-text {
      color: #928c8c;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
      letter-spacing: -0.12px;
    }

    &:hover .user-index {
      color: #ff347d;
    }

    .nodata {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 37px;
    }
  }
`;
