import { Avatar, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { getFirstLetter } from 'utils/helpers';

const ViewerTab = ({ currentUser, chatRTC }: any) => {
  const [members, setMembers] = useState<
    { avatar: string; userID: string; nick: string }[]
  >([]);

  useEffect(() => {
    let members: { avatar: string; userID: string; nick: string }[] = [];
    // for (let i = 0; i <= 20; i++) {
    //   const randomName = Math.random().toString(36).substring(2, 7);
    //   members.push({
    //     avatar: '',
    //     userID: `${i + 1}`,
    //     nick: randomName,
    //   });
    // }

    if (chatRTC?.memberList?.length >= 0) {
      setMembers([
        ...members,
        ...chatRTC?.memberList?.filter(
          item => String(currentUser?.id) !== item?.userID,
        ),
      ]);
    }
  }, [chatRTC?.memberList]);

  return (
    <div className="chat-container view-container">
      <div className="view-box">
        <Space size={[12, 12]} direction="vertical" style={{ width: '100%' }}>
          {chatRTC &&
            chatRTC?.memberList &&
            members?.map((item, i) => (
              <div key={i} className={`view-box-col ${i === 0 && 'top'}`}>
                <div className="user-item">
                  <div className="d-box">
                    <p className="user-index">
                      {i < 9 ? '0' + (i + 1) : i + 1}
                    </p>

                    <Avatar
                      src={item?.avatar}
                      shape="square"
                      style={{ minWidth: '48px', minHeight: '48px' }}
                    >
                      {getFirstLetter(item?.nick?.toLocaleUpperCase() || '')}
                    </Avatar>

                    <p className="user-name">{item?.nick}</p>
                  </div>
                </div>
              </div>
            ))}
        </Space>
      </div>
    </div>
  );
};

export default ViewerTab;
