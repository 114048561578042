import { Image, Tabs } from 'antd';
import React from 'react';
import InformationDetail from '../InformationDetail';
import ChatTab from './ChatTab';
import DonationTab from './DonationTab';
import ViewTab from './ViewTab';
import { useChatGroup } from './hooks';
import { InformationBox } from './styles';
import { breakpoints } from 'utils/media-breakpoint';
import ViewerTab from './ViewerTab';

const ChatGroup = ({
  isTheater,
  messageLists,
  setMessageLists,
  userStream,
  setInputMessage,
  sendButton,
  inputMessage,
  handleLocalChange,
  localStreamConfig,
  handleJoin,
  handleLeave,
  isMySelf,
  currentUser,
  receiveStatus,
  setReceiveStatus,
  dispatch,
  roomID,
  isDisabled,
  chatRTC,
}) => {
  const {
    tabs,
    tabKey,
    handleChangeTab,
    isTabletOrDesktop,
    giftKey,
    donationHistoryKey,
    categoriesFiltered,
    messageId,
    onToggleSeeAll,
    giftData,
    setIsOpenViewerModal,
    giftList,
  } = useChatGroup();

  return (
    <>
      {isTabletOrDesktop ? (
        <InformationBox
          isTheater={isTheater}
          onlyChat={tabs?.length === 1}
          isOwner={currentUser?.id == userStream?.id}
          isMySelf={isMySelf}
          breakpoints={breakpoints}
        >
          {isTheater && (
            <InformationDetail
              setMessageLists={setMessageLists}
              localStreamConfig={localStreamConfig}
              isTheater={isTheater}
              userStream={userStream}
              onChange={handleLocalChange}
              handleJoin={handleJoin}
              handleLeave={handleLeave}
              isMySelf={isMySelf}
              setIsOpenViewerModal={setIsOpenViewerModal}
            />
          )}
          <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
            <Tabs
              onChange={handleChangeTab}
              activeKey={tabKey}
              items={tabs?.map((t, i) => {
                if (
                  (giftKey == t?.key && currentUser?.id == userStream?.id) ||
                  (!isMySelf && t?.key === '4')
                ) {
                  return;
                }
                return {
                  label: (
                    <span key={i}>
                      <Image
                        alt="avatar"
                        preview={false}
                        src={t?.icon}
                        width={20}
                        height={20}
                        style={{
                          filter:
                            tabKey === t?.key ? 'brightness(0) invert(1)' : '',
                        }}
                      />
                      {t?.name}
                    </span>
                  ),
                  key: t?.key,
                };
              })}
            />

            {tabKey === '1' && (
              <ChatTab
                isMySelf={isMySelf}
                messageLists={messageLists}
                messageId={messageId}
                onToggleSeeAll={onToggleSeeAll}
                currentUser={currentUser}
                setInputMessage={setInputMessage}
                sendButton={sendButton}
                inputMessage={inputMessage}
                setReceiveStatus={setReceiveStatus}
                dispatch={dispatch}
                isDisabled={isDisabled}
                roomID={roomID}
                giftList={giftList}
              />
            )}

            {tabKey === '2' && (
              <DonationTab
                giftTypeData={categoriesFiltered}
                giftData={giftData}
                currentUser={currentUser}
                userStream={userStream}
                roomID={roomID}
                isDisabled={isDisabled}
              />
            )}

            {tabKey === '3' && (
              <ViewTab
                giftList={giftList}
                isMySelf={isMySelf}
                messageLists={messageLists}
                roomID={roomID}
              />
            )}

            {isMySelf && tabKey === '4' && (
              <ViewerTab currentUser={currentUser} chatRTC={chatRTC} />
            )}
          </div>
        </InformationBox>
      ) : (
        <></>
      )}
    </>
  );
};

export default ChatGroup;
