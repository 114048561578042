import { Modal, Button } from 'antd';
import styled from 'styled-components';

export const SteamListModal = styled(Modal)<any>`
  .ant-modal-title {
    text-align: center;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 34px;
    font-weight: 700;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (max-width: 703px) {
      font-size: 24px;
    }
  }

  .ant-modal-close-x {
    .anticon-close {
      color: #eb7d17;
      font-size: 20px;
    }
  }

  .ant-modal-content {
    position: relative;
    max-height: 808px;
    min-height: 600px;
    height: max-content;
    @media only screen and (max-width: 703px) {
      min-height: auto;
    }
  }

  .search-container {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    gap: 10px;
    padding: 10px 0px;

    @media only screen and (max-width: 703px) {
      display: flex;
      flex-direction: column-reverse;
    }
    .search-content {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: flex-end;
    }
    .ant-input-affix-wrapper {
      height: 44px;
      border-radius: 10px;
      padding-left: 20px;
      &:focus,
      &:hover {
        border-color: #eb7d17;
        box-shadow: none;
      }
    }

    .ant-input-suffix {
      span {
        color: #000;
        font-size: 20px;
      }
    }
  }

  .user-stream-list__bg {
    min-height: 400px;
    background: #f3f4f6;
    border: 1px solid #928c8c;
    border-radius: 0px 0px 20px 20px;
    padding: 10px;
    overflow: auto;
    max-height: 600px;
  }

  .user-stream-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    @media only screen and (max-width: 703px) {
      grid-template-columns: repeat(2, 1fr);
    }
    .item-user {
      width: 100%;
      cursor: pointer;
    }

    .ant-avatar {
      background: #ff347d;
      font-size: 60px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      margin: auto;
    }

    .nodata {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 37px;
    }
  }

  .pagination {
    padding-top: 25px;
  }
  .ant-pagination .ant-pagination-item a {
    font-weight: 400;
    font-size: 16px;
  }
  .ant-pagination .ant-pagination-item-active {
    border-color: #eb7d17;
  }
  .ant-pagination .ant-pagination-item-active a {
    color: #fff;
    background: #eb7d17;
  }
  .live-text {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eb7d17;
    color: #fff;
    border-radius: 16px;
    width: 57px;
    height: 18px;

    .live-icon {
      .ant-image {
        font-family: unset;
      }
    }

    .dot {
      display: block;
      width: 8px;
      height: 8px;
      margin-right: 5px;
      border-radius: 100%;
      background: #fff;
    }
  }
  .view-info {
    position: absolute;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    left: 5px;
    bottom: 11px;
    gap: 12px;
    z-index: 2;
    p {
      color: #fff;
      font-size: 14px;
      display: flex;
      gap: 6px;
    }
    .live-text {
      align-items: center;
      padding: 5px 10px;
      background: #eb7d17;
      color: #fff;
      border-radius: 16px;
      height: 18px;
      font-size: 9px;
      font-weight: 600;
      span {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background: #fff;
      }
    }
  }
`;

export const CustomButton = styled(Button)<any>`
  &.ant-btn-primary {
    background-color: #eb7d17;
    &:hover {
      opacity: 0.8;
      background-color: #eb7d17;
    }
  }
`;
