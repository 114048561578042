import {
  forgotPasswordRequest,
  loginOAuthPostRequest,
  loginOAuthRequest,
  loginRequest,
  registerRequest,
  registerVerifyRequest,
  resendOtpRequest,
  resetPasswordRequest,
  userRequest,
} from 'app/apis/auth';
import { call, put, takeLatest } from 'redux-saga/effects';
import { authActions as actions } from '.';
import { push } from 'connected-react-router';
import { PATH } from 'app/constants/path';
import { actionsToast } from 'app/pages/ToastPage/slice';
import { toast } from 'react-toastify';

function* login(action: any) {
  try {
    const { values, searchParam } = action.payload;
    const res = yield call(loginRequest, values);
    yield put(actions.loginSuccess(res));
    yield put(actions.getUser({}));

    /**
     * Old flow
     */

    if (searchParam) {
      yield put(push(`${PATH.STREAM}/${searchParam}`));
    } else {
      yield put(push(PATH.LIST_STREAM));
    }

    /**
     * New flow
     * No.36
     * https://cmcglobalcompany.sharepoint.com/:x:/r/sites/CMCGlobal/DuAnCMCG/2023/CJP_DJ7_Zept%20LLC%20(Zept%E5%90%88%E5%90%8C%E4%BC%9A%E7%A4%BE)_Livestream%20on%20Metaverse/N01%20-%20Customer%27s%20Requirements/A.%20Original/FB_KH/%E3%80%90Livestream%20Meta%E3%80%91%20Feedback%20KH.xlsx?d=w2b3d517d3ab54fa4b24ad950003ee0d2&csf=1&web=1&e=dxM3KQ&nav=MTZfVmlldzFfezhDRTlFNUYwLUFEOTItNDQ3RS1BN0Q1LTIyQ0JFRTU0QUZFMX0
     */
    // yield put(push(PATH.LIST_STREAM));
  } catch (error: any) {
    yield put(actions.loginFailed(error));
    yield put(
      actionsToast.openToast({
        message: error.response.data.message,
        type: 'error',
      }),
    );
  }
}

function* loginOAuth(action: any) {
  try {
    const { values, searchParam } = action.payload;
    const res = yield call(loginOAuthPostRequest, values);
    yield put(actions.loginOAuthSuccess(res));
    yield put(actions.getUser({}));

    if (searchParam) {
      yield put(push(`${PATH.STREAM}/${searchParam}`));
    } else {
      yield put(push(PATH.LIST_STREAM));
    }
  } catch (error: any) {
    yield put(actions.loginFailed(error));
    yield put(
      actionsToast.openToast({
        message: error.response.data.message,
        type: 'error',
      }),
    );
  }
}

function* register(action: any) {
  try {
    const res = yield call(registerRequest, action.payload);
    yield put(actions.registerSuccess(res));
    toast.success('Email has been sent successfully');
    yield put(
      push(`${PATH.REGISTER_VERIFICATION}?email=${action.payload.email}`),
    );
  } catch (error: any) {
    yield put(actions.registerFailed(error.response.data.message));
    yield put(
      actionsToast.openToast({
        message: error.response.data.message,
        type: 'error',
      }),
    );
  }
}

function* verify(action: any) {
  try {
    const res = yield call(registerVerifyRequest, action.payload);
    yield put(actions.verifySuccess(res));
    toast.success('Verify code successfully');
    yield put(push(PATH.PROFILE));
  } catch (error: any) {
    const { code, message } = error.response.data;
    yield put(actions.verifyFailed(error));
    yield put(
      actionsToast.openToast({
        message: message,
        type: 'error',
      }),
    );
  }
}

function* resendCode(action: any) {
  try {
    const res = yield call(resendOtpRequest, action.payload);
    yield put(actions.resendCodeSuccess(res));
    toast.success('Send code successfully');
    yield put(actionsToast.closeToast({}));
  } catch (error: any) {
    yield put(actions.resendCodeFailed(error));
    yield put(
      actionsToast.openToast({
        message: error.response.data.message,
        type: 'error',
      }),
    );
  }
}

function* forgotPassword(action: any) {
  try {
    const res = yield call(forgotPasswordRequest, action.payload);
    yield put(actions.forgotPasswordSuccess(res));
    toast.success('Email has been sent successfully');
    // yield put(
    //   actionsToast.openToast({
    //     message: "Email has been sent successfully",
    //     type: "success",
    //   })
    // );
    yield put(push(`${PATH.RESET_PASSWORD}?email=${action.payload.email}`));
  } catch (error: any) {
    yield put(actions.forgotPasswordFailed(error.response.data.message));
    yield put(
      actionsToast.openToast({
        message:
          'We can’t find any User with this email,<br/>please check & try again!',
        type: 'sorry',
        titleButton: 'Try again!',
      }),
    );
  }
}

function* resetPassword(action: any) {
  try {
    const res = yield call(resetPasswordRequest, action.payload);
    yield put(actions.resetPasswordSuccess(res));
    toast.success('Your password has been changed successfully');
    // yield put(
    //   actionsToast.openToast({
    //     message: "Your password has been changed successfully",
    //     type: "success",
    //   })
    // );
    yield put(push(PATH.LOGIN));
  } catch (error: any) {
    yield put(actions.resetPasswordFailed(error.response.data.message));
    yield put(
      actionsToast.openToast({
        message: error.response.data.message,
        type: 'error',
      }),
    );
  }
}

function* getUser(action: any) {
  try {
    const res = yield call(userRequest);
    yield put(actions.getUserSuccess(res));
  } catch (error: any) {
    yield put(actions.getUserFailed(error));
    if (error.response.status !== 403) {
      yield put(
        actionsToast.openToast({
          message: error.response.data.message,
          type: 'error',
          isErrorToken: true,
        }),
      );
    }
  }
}

export function* authSaga() {
  yield takeLatest(actions.login.type, login);
  yield takeLatest(actions.loginOAuth.type, loginOAuth);
  yield takeLatest(actions.register.type, register);
  yield takeLatest(actions.verify.type, verify);
  yield takeLatest(actions.resendCode.type, resendCode);
  yield takeLatest(actions.forgotPassword.type, forgotPassword);
  yield takeLatest(actions.resetPassword.type, resetPassword);
  yield takeLatest(actions.getUser.type, getUser);
}
