import React, { useState, useEffect } from 'react';
import { Image, Modal } from 'antd';
import styled from 'styled-components';

import { useDonation } from './hooks';
import DonationTab from './DonationTab';
import { GiftBox } from 'styles/image';

const DonationModal = ({
  isModalOpen,
  roomID,
  currentUser,
  userStream,
  onCloseModal,
}: any) => {
  const { giftTypeData, giftData } = useDonation();

  const [categoriesFiltered, setCategoriesFiltered] = useState<any[]>([]);

  useEffect(() => {
    if (giftTypeData && giftTypeData.length > 0) {
      const filtered = giftTypeData.filter(
        (item: any) => item.isPublic === true,
      );

      setCategoriesFiltered(filtered);
    }
  }, [giftTypeData]);

  return (
    <ModalWrapper
      open={isModalOpen}
      onCancel={onCloseModal}
      footer={null}
      getContainer={document.getElementById('viewer') as HTMLElement}
    >
      <DonationModalBox isOwner={currentUser?.id === userStream?.id}>
        <p className="title">
          <Image src={GiftBox} preview={false} />
          <span>Gift</span>
        </p>
        {giftTypeData ? (
          <DonationTab
            onCloseModal={onCloseModal}
            giftTypeData={categoriesFiltered}
            giftData={giftData}
            currentUser={currentUser}
            userStream={userStream}
            roomID={roomID}
          />
        ) : (
          <></>
        )}
      </DonationModalBox>
    </ModalWrapper>
  );
};

export default DonationModal;

const ModalWrapper = styled(Modal)`
  .ant-modal-close {
    display: none;
  }

  .ant-modal-content {
    height: calc(
      100vh - 248px
    ); // fallback for browsers not supporting dvh units
    max-height: calc(
      100vh - 248px
    ); // fallback for browsers not supporting dvh units
    height: calc(100dvh - 248px);
    max-height: calc(100dvh - 248px);
    padding: 10px;
    background: #231f1f;
  }
`;

export const DonationModalBox = styled.div<any>`
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
    color: #fff;

    .ant-image {
      width: auto;
    }

    img {
      filter: brightness(0) invert(1) !important;
      width: 24px;
      height: 24px;
    }
  }

  .chat-container {
    width: calc(100% - 20px);
    position: absolute;
    border: 1px solid #494443;
    padding: 5px;
    background: #363131;
    border-radius: 10px;
    height: calc(100% - 50px);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 54px;
  }

  .gift-container {
    display: block;

    .gift-type-box {
      position: relative;

      ul {
        display: -webkit-box;
        margin-top: 0;
        list-style-type: none;
        border-bottom: 1px solid #b0abab;
        padding-left: 0;
        overflow: auto;
        display: flex;
        justify-content: space-between;
        padding-right: 80px;
        padding-bottom: 5px;
        margin-bottom: 5px;

        li {
          position: relative;
          text-align: center;
          padding: 2px 0;
          width: 120px;
          margin: 5px 0;
          cursor: pointer;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: #d1d5db;
          width: 100%;
          font-weight: bold;

          &::after {
            content: '';
            position: absolute;
            right: 0;
            width: 1px;
            height: 24px;
            border-right: 1px solid #5f5858;
          }

          span {
            color: #5f5858;
          }
        }

        li.active {
          span {
            padding: 5px 15px;
            border-radius: 8px;
            background: #ff347d;
            color: #fff;
          }
        }
      }

      .group_btn {
        position: absolute;
        top: 5px;
        right: 10px;

        button {
          border: 0;
          background-color: #928c8c;
          padding: 5px;
          cursor: not-allowed;

          &:not(:disabled) {
            cursor: pointer;
            img {
              filter: brightness(0) invert(1);
            }
          }
        }

        .btn-next {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        .btn-prev {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }

    .gift-list-box {
      height: calc(100% - 60px);
      overflow-y: scroll;
      overflow-x: hidden;
      ::-webkit-scrollbar-track {
        background-color: #363131;
      }

      ::-webkit-scrollbar {
        width: 2px;
        background-color: #363131;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #d9d9d9;
        border: 1px solid #d9d9d9;
      }

      ul {
        margin-top: 0;
        list-style-type: none;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 20px;
        row-gap: 10px;
        padding-left: 0;
        @media only screen and (max-width: 400px) {
          grid-template-columns: repeat(2, 1fr);
          row-gap: 0;
        }
        li.gift-item {
          margin: 5px 0;
          display: flex;
          justify-content: center;
          flex-direction: column;
          flex: auto;
          cursor: pointer;
          width: 100%;

          .gift-image-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: #494443;
            border: 1px solid #5f5858;
            border-radius: 5px;
            height: 140px;

            img {
              margin: 5px 15px 15px 15px;
            }
          }

          .gift-selected {
            background: #494443;
            border: 1px solid #ff347d;
          }

          .gift-name {
            color: #ffffff;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px; /* 166.667% */
          }

          .gift-point {
            color: #ff347d;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 12px; /* 75% */
          }

          .gift-point-text {
            color: #928c8c;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
          }
        }
      }
    }

    .gift-send-button {
      position: absolute;
      left: 0;
      width: 100%;
      height: 52px;
      border-radius: 10px;
      border: none;
      background: #eb7d17;
      color: #ffffff;
      margin-left: 0;
      margin-top: 25px;
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
    }

    .gift-send-button.isDisabled {
      cursor: not-allowed;
      opacity: 0.6;
    }

    .popup-confirm-gift-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .popup-confirm-gift-are {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0 auto;
        padding: 40px;
        width: calc(100% - 40px);
        height: 320px;
        background: white;
        border-radius: 15px;
        border: 1px solid #eb7d;
        display: grid;
        row-gap: 20px;

        .popup-content {
          text-align: center;

          h2 {
            font-size: 34px;
            font-weight: 700;
          }

          span {
            font-size: 14px;
            font-weight: 400;
          }
        }

        .popup-header {
          text-align: center;
        }

        .popup-footer {
          margin-top: 30px;
          text-align: center;

          .gift-confirm-button {
            width: calc(50% - 20px);
            height: 52px;
            border-radius: 7px;
            border: none;
            font-weight: bold;
            cursor: pointer;
          }

          .yes-button {
            background: #eb7d17;
            color: #ffffff;
            margin-left: 20px;
          }
        }
      }
    }
  }
`;
