import React, { useEffect, useState } from 'react';
import { HeartFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { MetaButton } from 'app/components/Forms';
import { heartCrackFill, FuchaLogo } from 'styles/image';
import { PATH } from 'app/constants/path';
import { breakpoints } from 'utils/media-breakpoint';
import { Wrapper } from './styles';
import { MetaHelmet } from 'app/components/MetaHelmet';

export const ConfirmedAge18 = () => {
  const [isHiddenLogo18, setIsHiddenLogo18] = useState(false);
  const [isHiddenBlockConfirm, setIsHiddenBlockConfirm] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const timeoutShowLogo = setTimeout(() => {
      setIsHiddenLogo18(true);
    }, 2000);

    const timeoutShowBlockConfirm = setTimeout(() => {
      setIsHiddenBlockConfirm(true);
    }, 3000);

    return () => {
      clearTimeout(timeoutShowLogo);
      clearTimeout(timeoutShowBlockConfirm);
    };
  }, []);

  return (
    <Wrapper breakpoints={breakpoints}>
      <MetaHelmet title="FU-CHA" />
      <div
        className="logo-18"
        style={{ display: isHiddenLogo18 ? 'none' : 'block' }}
      >
        <img src={FuchaLogo} className="logo-icon" alt="logo 18" />
        <hr className="logo-18__line" />
      </div>
      <div
        className="logo-18-second"
        style={{ opacity: isHiddenBlockConfirm ? '1' : '0' }}
      >
        <img src={FuchaLogo} className="logo-icon" alt="logo 18" />
      </div>
      <div
        className="confirm-block"
        style={{ opacity: isHiddenBlockConfirm ? '1' : '0' }}
      >
        <div className="confirm-block__icon">
          <img src={heartCrackFill} alt="heart" width={48} />
        </div>
        <div className="confirm-block__title">
          <p className="title">Are you over 18?</p>
          <p className="desc">
            This website requires you to be 18 years or to enter.
          </p>
        </div>
        <div className="confirm-block__btn">
          <MetaButton
            className="btn-confirm"
            type="primary"
            text="Yessss, I'm in"
            onClick={() => navigate(PATH.LOGIN)}
          />
          <div className="list-icon-heart">
            {[...Array(15)].map((item, index) => {
              return (
                <HeartFilled
                  key={index}
                  className={`icon-heart-item icon-heart-${index + 1}`}
                />
              );
            })}
          </div>
        </div>
        <div>
          <MetaButton
            className="btn-out"
            type="primary"
            text="No, let's me out"
            onClick={() => navigate(0)}
          />
        </div>
      </div>
      <div className={`bg ${isHiddenBlockConfirm && 'bg-blur'}`}></div>
    </Wrapper>
  );
};
