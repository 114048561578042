import styled from 'styled-components';
import { BgConfirmedAge18 } from 'styles/image';

export const Wrapper = styled.div<any>`
  position: relative;
  height: 100vh;
  overflow: hidden;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: url(${BgConfirmedAge18});
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bg-blur {
    filter: blur(10px);
    overflow: hidden;
    animation-name: animation-bg-blur;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    transform: scale(110%);
  }

  @keyframes animation-bg-blur {
    0% {
      filter: blur(0);
      transform: scale(100%);
    }
    100% {
      filter: blur(10px);
      transform: scale(110%);
    }
  }

  /* UI and animation logo */

  .logo-18 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 394px;
    padding-bottom: 15px;

    @media only screen and (max-width: ${props => props.breakpoints.mobile}) {
      width: 335px;
    }

    .logo-icon {
      width: 100%;
    }
  }

  .logo-18-second {
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 17%;
    transform: translate(-50%, -50%);
    width: 240px;
    animation-name: animation-logo-18;
    @media only screen and (max-width: ${props => props.breakpoints.mobile}) {
      animation-name: animation-logo-18-mobile;
    }

    animation-duration: 2s;
    animation-delay: 2s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;

    .logo-icon {
      width: 100%;
    }
  }

  @keyframes animation-logo-18 {
    0% {
      width: 394px;
      opacity: 0;
      transform: translate(-50%, 50%);
    }
    60% {
      opacity: 1;
      transform: translate(-50%, -55%);
    }
    80% {
      transform: translate(-50%, -45%);
    }
    100% {
      width: 240px;
      transform: translate(-50%, -50%);
    }
  }

  @keyframes animation-logo-18-mobile {
    0% {
      width: 335px;
      opacity: 0;
      transform: translate(-50%, 50%);
    }
    60% {
      opacity: 1;
      transform: translate(-50%, -55%);
    }
    80% {
      transform: translate(-50%, -45%);
    }
    100% {
      width: 240px;
      transform: translate(-50%, -50%);
    }
  }

  .logo-18__line {
    width: 0;
    border-style: none;
    height: 3px;
    background-color: #fff;
    animation-name: animation-line;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
  }

  @keyframes animation-line {
    0% {
      width: 10px;
    }
    100% {
      width: 100%;
    }
  }

  /* Confirm Block */
  .confirm-block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    width: 580px;
    height: 371px;
    padding: 50px;
    background-color: #231f1f;
    border-radius: 20px;
    animation-name: animation-confirm-block;
    animation-duration: 2s;
    animation-delay: 2s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;

    @media only screen and (max-width: ${props => props.breakpoints.mobile}) {
      width: 328px;
      height: 361px;
      padding: 50px 20px;
    }
  }

  @keyframes animation-confirm-block {
    0% {
      opacity: 0;
      transform: translate(-50%, 500%);
    }
    60% {
      opacity: 1;
      transform: translate(-50%, -55%);
    }
    80% {
      transform: translate(-50%, -45%);
    }
    100% {
      transform: translate(-50%, -50%);
    }
  }

  .confirm-block__icon {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .confirm-block__title {
    margin-top: 30px;
    text-align: center;
    color: #fff;

    .title {
      font-size: 34px;
      font-weight: 700;

      @media only screen and (max-width: ${props => props.breakpoints.mobile}) {
        font-size: 28px;
      }
    }

    .desc {
      font-size: 14px;
      font-weight: 400;
      color: #928c8c;

      @media only screen and (max-width: ${props => props.breakpoints.mobile}) {
        font-size: 12px;
      }
    }
  }

  /* Block button confirm */

  .confirm-block__btn {
    position: relative;
    z-index: 100;
    margin-top: 40px;
  }

  .btn-confirm {
    width: 180px;
    height: 52px;
    background-color: #ff347d;

    &:hover {
      background-color: #ff347d !important;
      box-shadow: 0px 0px 24px 0px #ff7ead;
      cursor: pointer;
      opacity: 1;
    }
  }

  .icon-heart-item {
    position: absolute;
    top: 12px;
    left: 78px;
    color: #ff347d;
  }

  .icon-heart-1 {
    font-size: 11.97px;
    rotate: -46deg;
    z-index: -1;
    animation: animation-defaut-heart-1 1s ease alternate;
  }

  .btn-confirm:hover + .list-icon-heart .icon-heart-1 {
    top: -34px;
    left: -81px;
    animation: animation-heart-1 1s ease alternate;
  }

  @keyframes animation-heart-1 {
    0% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
    60% {
      opacity: 1;
      top: -34px;
      left: -81px;
    }
  }

  @keyframes animation-defaut-heart-1 {
    0% {
      opacity: 1;
      top: -34px;
      left: -81px;
    }
    60% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
  }

  .icon-heart-2 {
    font-size: 29.67px;
    rotate: -43deg;
    z-index: -1;
    animation: animation-defaut-heart-2 1s ease alternate;
  }

  .btn-confirm:hover + .list-icon-heart .icon-heart-2 {
    top: -22px;
    left: -47px;
    animation: animation-heart-2 1s ease alternate;
  }

  @keyframes animation-heart-2 {
    0% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
    60% {
      opacity: 1;
      top: -22px;
      left: -47px;
    }
  }

  @keyframes animation-defaut-heart-2 {
    0% {
      opacity: 1;
      top: -22px;
      left: -47px;
    }
    60% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
  }

  .icon-heart-3 {
    font-size: 18.42px;
    rotate: -21deg;
    z-index: -1;
    animation: animation-defaut-heart-3 1s ease alternate;
  }

  .btn-confirm:hover + .list-icon-heart .icon-heart-3 {
    top: -9.45px;
    left: 7.14px;
    animation: animation-heart-3 1s ease alternate;
  }

  @keyframes animation-heart-3 {
    0% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
    60% {
      opacity: 1;
      top: -9.45px;
      left: 7.14px;
    }
  }

  @keyframes animation-defaut-heart-3 {
    0% {
      opacity: 1;
      top: -9.45px;
      left: 7.14px;
    }
    60% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
  }

  .icon-heart-4 {
    font-size: 20.27px;
    rotate: 12deg;
    z-index: -1;
    animation: animation-defaut-heart-4 1s ease alternate;
  }

  .btn-confirm:hover + .list-icon-heart .icon-heart-4 {
    top: -63.97px;
    left: 28.45px;
    animation: animation-heart-4 1s ease alternate;
  }

  @keyframes animation-heart-4 {
    0% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
    60% {
      opacity: 1;
      top: -63.97px;
      left: 28.45px;
    }
  }

  @keyframes animation-defaut-heart-4 {
    0% {
      opacity: 1;
      top: -63.97px;
      left: 28.45px;
    }
    60% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
  }

  .icon-heart-5 {
    font-size: 16px;
    rotate: 44deg;
    z-index: -1;
    animation: animation-defaut-heart-5 1s ease alternate;
  }

  .btn-confirm:hover + .list-icon-heart .icon-heart-5 {
    top: -43.27px;
    left: 90.33px;
    animation: animation-heart-5 1s ease alternate;
  }

  @keyframes animation-heart-5 {
    0% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
    60% {
      opacity: 1;
      top: -43.27px;
      left: 90.33px;
    }
  }

  @keyframes animation-defaut-heart-5 {
    0% {
      opacity: 1;
      top: -43.27px;
      left: 90.33px;
    }
    60% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
  }

  .icon-heart-6 {
    font-size: 11.38px;
    rotate: -43deg;
    z-index: -1;
    animation: animation-defaut-heart-6 1s ease alternate;
  }

  .btn-confirm:hover + .list-icon-heart .icon-heart-6 {
    top: -59.51px;
    left: 165.59px;
    animation: animation-heart-6 1s ease alternate;
  }

  @keyframes animation-heart-6 {
    0% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
    60% {
      opacity: 1;
      top: -59.51px;
      left: 165.59px;
    }
  }

  @keyframes animation-defaut-heart-6 {
    0% {
      opacity: 1;
      top: -59.51px;
      left: 165.59px;
    }
    60% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
  }

  .icon-heart-7 {
    font-size: 12.45px;
    rotate: 38deg;
    z-index: -1;
    animation: animation-defaut-heart-7 1s ease alternate;
  }

  .btn-confirm:hover + .list-icon-heart .icon-heart-7 {
    top: -28px;
    left: 222px;
    animation: animation-heart-7 1s ease alternate;
  }

  @keyframes animation-heart-7 {
    0% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
    60% {
      opacity: 1;
      top: -28px;
      left: 222px;
    }
  }

  @keyframes animation-defaut-heart-7 {
    0% {
      opacity: 1;
      top: -28px;
      left: 222px;
    }
    60% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
  }

  .icon-heart-8 {
    font-size: 19.17px;
    rotate: 124.85deg;
    z-index: -1;
    animation: animation-defaut-heart-8 1s ease alternate;
  }

  .btn-confirm:hover + .list-icon-heart .icon-heart-8 {
    top: 34.14px;
    left: 212.8px;
    animation: animation-heart-8 1s ease alternate;
  }

  @keyframes animation-heart-8 {
    0% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
    60% {
      opacity: 1;
      top: 34.14px;
      left: 212.8px;
    }
  }

  @keyframes animation-defaut-heart-8 {
    0% {
      opacity: 1;
      top: 34.14px;
      left: 212.8px;
    }
    60% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
  }

  .icon-heart-9 {
    font-size: 27.75px;
    rotate: 222deg;
    z-index: -1;
    animation: animation-defaut-heart-9 1s ease alternate;
  }

  .btn-confirm:hover + .list-icon-heart .icon-heart-9 {
    top: 59.59px;
    left: 148.91px;
    animation: animation-heart-9 1s ease alternate;
  }

  @keyframes animation-heart-9 {
    0% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
    60% {
      opacity: 1;
      top: 59.59px;
      left: 148.91px;
    }
  }

  @keyframes animation-defaut-heart-9 {
    0% {
      opacity: 1;
      top: 59.59px;
      left: 148.91px;
    }
    60% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
  }

  .icon-heart-10 {
    font-size: 21.25px;
    rotate: -205.39deg;
    z-index: -1;
    animation: animation-defaut-heart-10 1s ease alternate;
  }

  .btn-confirm:hover + .list-icon-heart .icon-heart-10 {
    top: 91.97px;
    left: 118.27px;
    animation: animation-heart-10 1s ease alternate;
  }

  @keyframes animation-heart-10 {
    0% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
    60% {
      opacity: 1;
      top: 91.97px;
      left: 118.27px;
    }
  }

  @keyframes animation-defaut-heart-10 {
    0% {
      opacity: 1;
      top: 91.97px;
      left: 118.27px;
    }
    60% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
  }

  .icon-heart-11 {
    font-size: 11.43px;
    rotate: 192deg;
    z-index: -1;
    animation: animation-defaut-heart-11 1s ease alternate;
  }

  .btn-confirm:hover + .list-icon-heart .icon-heart-11 {
    top: 74.94px;
    left: 80.81px;
    animation: animation-heart-11 1s ease alternate;
  }

  @keyframes animation-heart-11 {
    0% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
    60% {
      opacity: 1;
      top: 74.94px;
      left: 80.81px;
    }
  }

  @keyframes animation-defaut-heart-11 {
    0% {
      opacity: 1;
      top: 74.94px;
      left: 80.81px;
    }
    60% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
  }

  .icon-heart-12 {
    font-size: 18.64px;
    rotate: 200deg;
    z-index: -1;
    animation: animation-defaut-heart-12 1s ease alternate;
  }

  .btn-confirm:hover + .list-icon-heart .icon-heart-12 {
    top: 88.94px;
    left: 34.64px;
    animation: animation-heart-12 1s ease alternate;
  }

  @keyframes animation-heart-12 {
    0% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
    60% {
      opacity: 1;
      top: 88.94px;
      left: 34.64px;
    }
  }

  @keyframes animation-defaut-heart-12 {
    0% {
      opacity: 1;
      top: 88.94px;
      left: 34.64px;
    }
    60% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
  }

  .icon-heart-13 {
    font-size: 32.08px;
    rotate: 199deg;
    z-index: -1;
    animation: animation-defaut-heart-13 1s ease alternate;
  }

  .btn-confirm:hover + .list-icon-heart .icon-heart-13 {
    top: 52.36px;
    left: -5.43px;
    animation: animation-heart-13 1s ease alternate;
  }

  @keyframes animation-heart-13 {
    0% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
    60% {
      opacity: 1;
      top: 52.36px;
      left: -5.43px;
    }
  }

  @keyframes animation-defaut-heart-13 {
    0% {
      opacity: 1;
      top: 52.36px;
      left: -5.43px;
    }
    60% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
  }

  .icon-heart-14 {
    font-size: 10.08px;
    rotate: 196.79deg;
    z-index: -1;
    animation: animation-defaut-heart-14 1s ease alternate;
  }

  .btn-confirm:hover + .list-icon-heart .icon-heart-14 {
    top: 64.62px;
    left: -48.79px;
    animation: animation-heart-14 1s ease alternate;
  }

  @keyframes animation-heart-14 {
    0% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
    60% {
      opacity: 1;
      top: 64.62px;
      left: -48.79px;
    }
  }

  @keyframes animation-defaut-heart-14 {
    0% {
      opacity: 1;
      top: 64.62px;
      left: -48.79px;
    }
    60% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
  }

  .icon-heart-15 {
    font-size: 14.63px;
    rotate: -110.5deg;
    z-index: -1;
    animation: animation-defaut-heart-15 1s ease alternate;
  }

  .btn-confirm:hover + .list-icon-heart .icon-heart-15 {
    top: 33.84px;
    left: -93.27px;
    animation: animation-heart-15 1s ease alternate;
  }

  @keyframes animation-heart-15 {
    0% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
    60% {
      opacity: 1;
      top: 33.84px;
      left: -93.27px;
    }
  }

  @keyframes animation-defaut-heart-15 {
    0% {
      opacity: 1;
      top: 33.84px;
      left: -93.27px;
    }
    60% {
      opacity: 0;
      top: 12px;
      left: 78px;
    }
  }

  .btn-out {
    width: 180px;
    height: 31px;
    margin-top: 15px;
    font-size: 12px;
    color: #928c8c !important;
    background-color: #d2cfce;

    &:hover {
      background-color: #d2cfce !important;
    }
  }
`;
