import { Avatar, Form, Image, Popconfirm, Space, Tag, Typography } from 'antd';
import { MetaButton } from 'app/components/Forms';
import React, { useEffect } from 'react';
import {
  CameraOffIcon,
  CameraOnIcon,
  StartIcon,
  StopIcon,
  VoiceIcon,
  VoiceOffIcon,
  PersonIcon,
} from 'styles/image';
import { getFirstLetter } from 'utils/helpers';
import { breakpoints } from 'utils/media-breakpoint';
import { useInformationDetail } from './hooks';
import {
  ButtonStart,
  IconMySelf,
  IsViewListIcon,
  InformationBox,
} from './styles';
import SettingStreamModal from '../SettingStreamModal';
import { settingViewLivePointRequest } from 'app/apis/profile';
import { toast } from 'react-toastify';

const InformationDetail = ({
  userStream,
  onChange,
  localStreamConfig,
  handleJoin,
  handleLeave,
  isMySelf,
  setIsOpenViewerModal,
  setDefaultMedia,
  setMessageLists,
  roomID,
}: any) => {
  const {
    isDesktop,
    isFollow,
    handleFollowed,
    currentPerson,
    currentTotalFollowers,
    isOpen,
    setIsOpen,
  } = useInformationDetail({
    userId: userStream?.id,
    userStreamTotalFollowers: userStream?.total_followers,
  });

  const [form] = Form.useForm();

  const handleStart = () => {
    if (!localStreamConfig?.stream) {
      handleJoin();
    } else {
      handleLeave();
    }
  };

  const settingViewLivePoint = async data => {
    try {
      await settingViewLivePointRequest(data);
    } catch (err) {
      toast.error('setting point view live fail');
    }
  };

  const onSubmitSetting = values => {
    Promise.resolve()
      .then(() => {
        setMessageLists([]);
        setDefaultMedia({
          muteVideo: !values.camera,
          muteAudio: !values.microphone,
          facingMode: values.facingMode,
        });
      })
      .then(() => {
        handleStart();
      });
  };

  useEffect(() => {
    if (roomID && isMySelf) {
      const formData = form.getFieldsValue(true);
      settingViewLivePoint({
        room_id: roomID && Number(roomID),
        require_point: formData.quantity && Number(formData.quantity),
      });
    }
  }, [roomID]);

  // const onToggleConfirm = e => {
  //   Promise.resolve()
  //     .then(() => {
  //       setDefaultMedia({
  //         muteVideo: false,
  //         muteAudio: false,
  //       });
  //     })
  //     .then(() => {
  //       handleStart();
  //     });
  // };

  // const onToggleCancel = e => {
  //   Promise.resolve()
  //     .then(() => {
  //       setDefaultMedia({
  //         muteVideo: true,
  //         muteAudio: true,
  //       });
  //     })
  //     .then(() => {
  //       handleStart();
  //     });
  // };

  return (
    <InformationBox isMySelf={isMySelf} breakpoints={breakpoints}>
      {isMySelf ? (
        <>
          {/* <Popconfirm
            title="マイクとカメラの設定は現在オフになっていますが、オンにしましょうか？"
            onConfirm={onToggleConfirm}
            onCancel={onToggleCancel}
            okText="はい"
            cancelText="いいえ"
            disabled={localStreamConfig?.stream}
          > */}
          <ButtonStart
            icon={
              <Image
                alt="icon"
                preview={false}
                src={!localStreamConfig?.stream ? StartIcon : StopIcon}
                width={11}
                height={14}
                style={{
                  borderRadius: '0px',
                }}
              />
            }
            type={!localStreamConfig?.stream ? 'primary' : 'normal'}
            onClick={() => {
              if (localStreamConfig?.stream) {
                handleLeave();
              } else {
                setMessageLists([]);
                setIsOpen(true);
              }
            }}
            text={!localStreamConfig?.stream ? 'スタート' : '停止'}
            breakpoints={breakpoints}
            className="btn-primary-pink"
          />
          {/* </Popconfirm> */}

          {isOpen && (
            <SettingStreamModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              form={form}
              setMessageLists={setMessageLists}
              onSubmitSetting={onSubmitSetting}
            />
          )}

          <div
            style={{
              display: 'flex',
              gap: '20px',
              justifyContent: 'flex-end',
              height: 'max-content',
            }}
          >
            {!isDesktop && isMySelf && localStreamConfig?.stream && (
              <IsViewListIcon
                className="icon-myself"
                onClick={() => {
                  setIsOpenViewerModal(true);
                }}
                breakpoints={breakpoints}
                isLocalStream={localStreamConfig?.stream}
                style={{ background: '#FF347D !important' }}
              >
                <img
                  alt="person-icon"
                  src={PersonIcon}
                  style={{
                    filter: 'brightness(10)',
                  }}
                />
              </IsViewListIcon>
            )}

            <IconMySelf
              className="icon-myself"
              onClick={() => {
                localStreamConfig?.stream && onChange({ name: 'audio' });
              }}
              breakpoints={breakpoints}
              isLocalStream={localStreamConfig?.stream}
              isMuted={localStreamConfig?.mutedAudio}
            >
              <img
                alt="icon"
                src={!localStreamConfig?.mutedAudio ? VoiceIcon : VoiceOffIcon}
              />
            </IconMySelf>

            <IconMySelf
              className="icon-myself"
              onClick={() => {
                localStreamConfig?.stream && onChange({ name: 'video' });
              }}
              breakpoints={breakpoints}
              isLocalStream={localStreamConfig?.stream}
              isMuted={localStreamConfig?.mutedVideo}
            >
              <img
                alt="icon"
                src={
                  !localStreamConfig?.mutedVideo ? CameraOnIcon : CameraOffIcon
                }
              />
            </IconMySelf>
          </div>
        </>
      ) : (
        <>
          <div>
            <Avatar
              className="custom-avatar"
              shape="square"
              src={currentPerson?.avatar_url ? currentPerson?.avatar_url : ''}
            >
              {getFirstLetter(currentPerson?.username || '')}
            </Avatar>
          </div>

          <div className="description-box">
            <div className="description-content">
              <div>
                <Typography.Title level={2} className="ellipsis-1">
                  {userStream?.username}
                </Typography.Title>

                <Space size={[32, 4]} wrap>
                  <p>
                    {currentTotalFollowers}
                    <span style={{ marginLeft: 3 }}>
                      {/* {currentTotalFollowers && currentTotalFollowers > 1
                        ? 'followers'
                        : 'follower'} */}
                      {currentTotalFollowers && currentTotalFollowers > 1
                        ? 'フォロワー'
                        : 'フォロワー'}
                    </span>
                  </p>
                </Space>
                <div className="button-box">
                  <MetaButton
                    type={isFollow ? 'primary' : 'normal'}
                    // text={isFollow ? 'Followed' : 'Follow'}
                    text={isFollow ? 'フォロー済み' : 'フォローする'}
                    onClick={() => handleFollowed(isFollow)}
                    style={{ width: '133px' }}
                    className="btn-primary-pink"
                  />
                </div>
              </div>

              {currentPerson?.labels?.length > 0 && (
                <div className="env-box">
                  <Space
                    size={[10, 4]}
                    wrap
                    style={{
                      justifyContent: 'flex-end',
                    }}
                  >
                    {currentPerson?.labels?.map((t, i) => (
                      <Tag
                        key={i}
                        style={{
                          background: t?.color,
                          color: '#fff',
                          border: 'none',
                          height: '24px',
                        }}
                      >
                        {t?.name}
                      </Tag>
                    ))}
                  </Space>
                </div>
              )}
            </div>

            {isDesktop && (
              <div className="de-info">
                <div
                  style={{ whiteSpace: 'pre-line' }}
                  className="ant-typography"
                  dangerouslySetInnerHTML={{ __html: userStream?.bio }}
                />
              </div>
            )}
          </div>
        </>
      )}

      {!isMySelf && !isDesktop && (
        <div
          className="de-info"
          style={{
            gridColumn: '1/3',
            gridRow: '2/3',
          }}
        >
          <div
            style={{ whiteSpace: 'pre-line' }}
            className="ant-typography"
            dangerouslySetInnerHTML={{ __html: userStream?.bio }}
          />
        </div>
      )}
    </InformationBox>
  );
};

export default InformationDetail;
