import { Form } from 'antd';
import { MetaButton, MetaInput } from 'app/components/Forms';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import {
  IS_ANDROID,
  IS_CHROME,
  IS_IPAD,
  IS_IPHONE,
  IS_SAFARI,
} from 'utils/constants';
import {
  regexEmail,
  regexIcon,
  regexPassword,
  regexSpecialCharacter,
  regexUsername,
} from 'utils/helpers';

const RegisterForm = ({ form, onFinish, isLoading }) => {
  const [readOnly, setReadOnly] = useState(true);

  function getDevice() {
    if (IS_IPAD) {
      return true;
    }
    if (IS_IPHONE) {
      return true;
    }
    if (IS_ANDROID) {
      return true;
    }
    if (IS_IPAD && IS_CHROME) {
      return true;
    }
    if (IS_IPAD && IS_SAFARI) {
      return true;
    }
    return false;
  }

  return (
    <FormContainer
      form={form}
      layout="vertical"
      onFinish={onFinish}
      className="form-login"
      autoComplete="off"
    >
      <MetaInput
        form={form}
        name="username"
        label="ユーザー名"
        subLabel="５文字以上で作成してください"
        isNormalize
        isRequired
        rules={[
          { required: true, message: 'ユーザー名を入力してください' },
          {
            pattern: regexUsername,
            message: '5～20文字以内で入力してください',
          },
          {
            pattern: regexIcon,
            message: '無効な文字です',
          },
          {
            pattern: regexSpecialCharacter,
            message: '無効な文字です',
          },
        ]}
        readOnly={!getDevice() ? readOnly : false}
        autoComplete="disabled"
        onFocus={() => setReadOnly(false)}
        onBlur={() => setReadOnly(true)}
      />

      <MetaInput
        form={form}
        label="パスワード"
        subLabel="8～32文字以内、大文字、小文字、数字、記号を含めてください"
        type="password"
        name="password"
        autoComplete="off"
        placeholder="パスワード"
        className="input-password"
        isRequired
        rules={[
          { required: true, message: 'パスワードを入力してください' },
          {
            pattern: regexPassword,
            message:
              '8～32文字以内、大文字、小文字、数字、記号を含めてください',
          },
          {
            pattern: regexIcon,
            message: '無効な文字です',
          },
        ]}
      />

      <MetaInput
        form={form}
        label="パスワードを再入力"
        type="password"
        name="confirmPassword"
        placeholder="パスワードを再入力"
        className="input-password"
        autoComplete="off"
        isRequired
        rules={[
          { required: true, message: 'パスワードを再入力してください' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('パスワードが一致しません'));
            },
          }),
        ]}
      />

      <MetaInput
        name="email"
        label="メールアドレス"
        placeholder="メールアドレスを入力してください"
        autoComplete="off"
        isNormalize
        isRequired
        rules={[
          { required: true, message: 'メールアドレスを入力してください' },
          {
            pattern: regexEmail,
            message: '無効なメールアドレスです',
          },
          {
            pattern: regexIcon,
            message: '無効な文字です',
          },
        ]}
        form={form}
      />

      <p
        className="mb-0"
        style={{
          color: '#FF347D',
          fontWeight: '600',
          fontSize: '14px',
        }}
      >
        * 空白が無いようにして下さい
      </p>

      <Form.Item>
        <MetaButton
          type="primary"
          className="btn-primary-pink"
          htmlType="submit"
          text="登録する"
          is_loading={isLoading}
        />
      </Form.Item>
    </FormContainer>
  );
};

export default RegisterForm;

const FormContainer = styled(Form)<any>`
  display: flex;
  flex-direction: column;
  gap: 10px;

  > div {
    margin-bottom: 0;
  }

  .ant-btn-primary {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
