import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import weekday from 'dayjs/plugin/weekday';
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { Header } from './Header/Loadable';
import { useLocation } from 'react-router-dom';
import { PATH } from 'app/constants/path';
import { loginBg } from 'styles/image';
// import { useAuthSlice } from "app/pages/AuthPage/slice";
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const MainLayout = ({ children }) => {
  const { pathname } = useLocation();
  // const { actions: actionsAuth } = useAuthSlice();

  // useEffect(() => {
  //   dispatch(actionsAuth.getPermissions({ limit: -1 }));
  // }, [actionsAuth, dispatch]);

  //  Google Analytic
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://www.googletagmanager.com/gtag/js?id=G-23TFE6MZSX';
  //   script.async = true;

  //   const head = document.head || document.getElementsByTagName('head')[0];
  //   head.appendChild(script);

  //   window.dataLayer = window.dataLayer || [];
  //   window.gtag = function gtag(...args: any[]): void {
  //     window.dataLayer.push(...args);
  //   };

  //   window.gtag('js', new Date());
  //   window.gtag('config', 'G-23TFE6MZSX');

  //   return () => {
  //     head.removeChild(script);
  //   };
  // }, []);

  return (
    <LayoutGlobal>
      <Header />
      <div className="content">{children}</div>
    </LayoutGlobal>
  );
};

const LayoutGlobal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 100vh; //fallback for browsers not supporting dvh units
  min-height: 100dvh;
  overflow-x: hidden;

  .content {
    flex: 1;
    position: relative;
    background-image: url('${loginBg}');
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;

    @media only screen and (max-width: 1024px) {
      background-image: none;
      background-color: #231f1f;
    }
  }
`;

export default MainLayout;
