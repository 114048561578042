import styled from 'styled-components';

export const SteamListUser = styled.div<any>`
  .list-stream-title {
    display: flex;
    justify-content: center;
    margin: 40px 0;
    color: #ff347d;

    @media only screen and (max-width: 1024px) {
      margin: 20px 0;
    }

    .text {
      font-size: 34px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      text-align: center;
      width: 100%;

      @media only screen and (max-width: 1024px) {
        font-size: 24px;
      }

      @media only screen and (max-width: 640.9998px) {
        width: 70%;
        letter-spacing: 1px;
        line-height: 25px;
        font-size: 20px;
      }
    }
  }

  .search-container {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    gap: 10px;
    padding: 10px 0px;
    margin: 0px 130px;
    margin-bottom: 20px;
    @media only screen and (max-width: 1024px) {
      margin: 0 20px;
      padding: 20px 0px;
    }
    @media only screen and (max-width: ${props => props.breakpoints.mobile}) {
      margin: 0 10px;
      padding-top: 0px;
      display: flex;
      flex-direction: column-reverse;
    }

    .search-content {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: flex-end;
      font-size: 14px;
      margin-left: 10px;
    }

    .ant-input-affix-wrapper {
      height: 44px;
      border-radius: 10px;
      padding-left: 20px;
      &:focus,
      &:hover {
        border-color: #eb7d17;
        box-shadow: none;
      }
    }

    .ant-input-suffix {
      span {
        color: #000;
        font-size: 20px;
      }
    }
  }

  .banner-advertisement {
    display: flex;
    justify-content: center;
    width: 62%;
    margin: 20px auto;
    img {
      border-radius: 15px;
    }

    @media only screen and (max-width: 1024px) {
      width: 96%;
    }
    @media only screen and (max-width: 703px) {
      width: 97%;
      margin-top: 10px;

      .ant-image {
        width: 100%;
      }
    }
  }

  .user-stream-list {
    display: flex;
    margin: 0px 120px;
    flex-wrap: wrap;
    padding-bottom: ${({ isShowPagination }: any) =>
      isShowPagination ? '72px' : '0'};
    @media only screen and (max-width: 1024px) {
      margin: auto;
      width: 98%;
      padding-bottom: ${({ isShowPagination }: any) =>
        isShowPagination ? '79px' : '0'};
    }
    @media only screen and (max-width: 703px) {
      margin: 0 5px;
      padding-bottom: ${({ isShowPagination }: any) =>
        isShowPagination ? '62px' : '0'};
    }

    .item-user {
      width: calc(100% / 5 - 20px);
      cursor: pointer;
      margin: 0 10px;
      margin-bottom: 10px;
      @media only screen and (max-width: 1024px) {
        width: calc((100% / 4) - 20px);
        margin: 0 10px;
        margin-bottom: 5px;
      }
      @media only screen and (max-width: 703px) {
        width: calc(100% / 2 - 8px);
        margin: 0 4px;
      }
    }

    .ant-avatar {
      background: #ff347d;
      font-size: 60px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      margin: auto;
    }
    .pagination {
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1000;
      width: 70%;
      padding: 20px 0;
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0%,
        #110f0f 5%,
        #110f0f 95%,
        rgba(0, 0, 0, 0) 100%
      );
      @media only screen and (max-width: 1024px) {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 27px;
        background-image: none;
        background-color: #231f1f;
      }
      @media only screen and (max-width: 703px) {
        padding: 15px 0;
      }
    }
    .ant-pagination .ant-pagination-item-link,
    .ant-pagination .ant-pagination-item-link:hover,
    .ant-pagination .ant-pagination-item a {
      font-weight: 400;
      font-size: 16px;
      background: #494443;
      color: #928c8c;
      border-radius: 4px;
    }
    .ant-pagination .ant-pagination-item-active {
      border-color: #ff347d;
    }
    .ant-pagination .ant-pagination-item-active a {
      color: #fff;
      background: #ff347d;
    }

    .ant-pagination .ant-pagination-item-ellipsis,
    .ant-pagination-item-link-icon {
      color: #5f5858 !important;
    }
  }

  .nodata {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 37px;
  }

  .live-text {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ff347d;
    color: #fff;
    border-radius: 16px;
    width: 81px;
    height: 28px;
    @media only screen and (max-width: 1024px) {
      width: 66px;
      height: 26px;
    }

    .live-icon {
      .ant-image {
        font-family: unset;
      }
    }

    .dot {
      display: block;
      width: 8px;
      height: 8px;
      margin-right: 5px;
      border-radius: 100%;
      background: #fff;
    }
  }
  .view-info {
    position: absolute;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    left: 10px;
    bottom: 10px;
    gap: 12px;
    z-index: 2;
    p {
      color: #fff;
      font-size: 14px;
      display: flex;
      gap: 6px;
    }
  }

  .user-name {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.14px;
    margin-bottom: 0;
    margin-top: 5px;
    color: #fff;
    @media only screen and (max-width: 1024px) {
      margin-top: 0;
    }
  }

  .follower {
    font-size: 14px;
    margin-bottom: 0;
    color: #928c8c;
  }
`;
