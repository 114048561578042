/**
 *
 * MetaTextArea
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { Input, Form, FormInstance } from 'antd';

interface Props {
  name: string;
  label?: string;
  rules?: any[];
  disabled?: boolean;
  placeholder?: string;
  rows?: number;
  style?: any;
  form: FormInstance<any>;
  onBlur?: any;
  maxLength?: number;
  isRequired?: boolean;
}

const { TextArea } = Input;
export const MetaTextArea = memo((props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const {
    name,
    label,
    rules,
    disabled,
    placeholder,
    rows,
    style,
    form,
    onBlur,
    maxLength,
    isRequired,
  } = props;
  const handleBlur = e => {
    const value = e.target.value;
    if (form) form.setFieldValue(name, value.trim());
    if (onBlur) onBlur(e);
  };

  return (
    <FormItem
      label={
        isRequired ? (
          label ? (
            <p>
              {label} <b style={{ color: '#c0392b' }}>*</b>
            </p>
          ) : null
        ) : label ? (
          <p>{label}</p>
        ) : null
      }
      name={name}
      rules={rules}
    >
      <InputInForm
        rows={rows}
        disabled={disabled || false}
        placeholder={placeholder}
        style={style}
        onBlur={handleBlur}
        maxLength={maxLength}
      />
    </FormItem>
  );
});

const InputInForm = styled(TextArea)<any>`
  padding: 12px 20px 12px 22px;
  border-radius: 10px;
  border-color: #5f5858;
  background-color: #494443;
  height: 44px;
  color: #fff;

  &:hover {
    border-color: #eb7d17;
  }

  &:focus {
    border-color: #eb7d17;
    box-shadow: 0px 0px 14px 0px #ff00001a;
  }

  &::placeholder {
    color: #5f5858;
    font-weight: 400;
    font-size: 14px;
  }

  &.inlines {
    border: 1px solid #eb7d17;
    border-radius: 8px;
    background: #fff;
  }
`;

export const FormItem = styled(Form.Item)<any>`
  width: 100%;
  margin-bottom: 10px;

  .ant-input-affix-wrapper-disabled {
    background: var(--color-gray-4) !important;
  }

  .ant-form-item-label {
    align-items: flex-start !important;
    padding-bottom: 4px;
    label {
      font-size: 14px;
      color: #928c8c;
      font-weight: 400;
    }
  }
  label {
    font-size: 14px;
    color: #928c8c;
    font-weight: 400;
    &::after {
      content: ${props => (props.label === ' ' ? '" "' : '":"')} !important;
    }
  }

  .ant-form-item-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 4px;
    label {
      font-size: 14px;
      color: #928c8c;
      font-weight: 400;
    }
  }
  .ant-form-item-explain-error {
    color: #e10000 !important;
  }
  .ant-form-item-explain-error:first-child {
    display: block;
  }

  .ant-input-status-error,
  .ant-input-affix-wrapper-status-error {
    border-color: #e10000 !important;
  }
`;
