import { Col, Row } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { DISPLAY_DATETIME_FORMAT } from 'app/constants';
import moment from 'moment';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { breakpoints } from 'utils/media-breakpoint';
import { usePointHistory } from './hooks';

const PointHistory = ({}: any) => {
  const isMobile = useMediaQuery({ maxWidth: '640px' });
  const { pointHistory, fetchPointHistory } = usePointHistory();

  useEffect(() => {
    fetchPointHistory();
  }, []);

  return !isMobile ? (
    <PointHistoryContainer breakpoints={breakpoints}>
      <div style={{ overflowX: 'auto' }}>
        <div className="table-container">
          <Row className="row-header">
            <Col xs={6} sm={6}>
              {/* Point */}
              ポイント
            </Col>
            <Col xs={3} sm={3}>
              {/* Quantity */}
              購入数
            </Col>
            <Col xs={10} sm={10}>
              {/* Price */}
              価格
            </Col>
            <Col xs={5} sm={5}>
              {/* Date */}
              日付
            </Col>
          </Row>

          <div className="row-body">
            {pointHistory &&
              pointHistory.length > 0 &&
              pointHistory?.map((item, index) => (
                <Row className="row-body-content" key={index}>
                  <Col xs={6} sm={6}>
                    <strong
                      className="ellipsis-1"
                      style={{ color: '#EB7D17', fontSize: '16px' }}
                    >
                      {new Intl.NumberFormat().format(
                        item.point_package.quantity * item?.quantity,
                      )}
                    </strong>
                  </Col>

                  <Col xs={3} sm={3}>
                    <span style={{ color: '#9CA3AF' }}>{item?.quantity}</span>
                  </Col>

                  <Col xs={10} sm={10}>
                    <strong className="ellipsis-1" style={{ color: '#1F2937' }}>
                      ¥{new Intl.NumberFormat().format(item.total)}
                    </strong>
                  </Col>

                  <Col xs={5} sm={5}>
                    <span style={{ color: '#9CA3AF' }}>
                      {moment(item?.created_at).format(DISPLAY_DATETIME_FORMAT)}
                    </span>
                  </Col>
                </Row>
              ))}
          </div>
        </div>
      </div>
    </PointHistoryContainer>
  ) : (
    <PointHistoryContainerMobile breakpoints={breakpoints}>
      <div style={{ overflowX: 'auto' }}>
        <div className="history-list">
          {pointHistory &&
            pointHistory.length > 0 &&
            pointHistory?.map((item, index) => (
              <div className="history-item" key={index}>
                <div className="history-item__point">
                  <div className="point-item">
                    <p className="point-item__title">Point</p>

                    <strong
                      className="ellipsis-1"
                      style={{ color: '#EB7D17', fontSize: '16px' }}
                    >
                      {new Intl.NumberFormat().format(
                        item.point_package.quantity,
                      )}
                    </strong>
                  </div>

                  <strong className="ellipsis-1" style={{ color: '#1F2937' }}>
                    ¥{new Intl.NumberFormat().format(item.total)}
                  </strong>
                </div>
                <div className="history-item__quantity">
                  <p style={{ color: '#9CA3AF' }}>
                    <span className="quantity-item__title">Quantity</span>
                    <span className="quantity-item__value">
                      {item?.quantity}
                    </span>
                  </p>
                  <p className="quantity-item__date">
                    {moment(item?.created_at).format(DISPLAY_DATETIME_FORMAT)}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </PointHistoryContainerMobile>
  );
};

export const PointHistoryContainer = styled.div<any>`
  .point-history-container {
    background: #363131;
    border-radius: 10px;
    border: 1px solid #494443;
    color: #928c8c;
    height: 360px;
    overflow-x: auto;
  }

  .table-container {
    border: 1px solid #494443;
    border-radius: 10px;
    background: #363131;
    padding: 17px 20px;

    .row-header {
      border-bottom: 1px solid #5F5858;
      padding: 12px 0px;
      padding-top: 0px;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 700;
      color: #928c8c;
      text-align: center;
    }
    .row-body {
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      .row-body-content {
        color: #928c8c;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        background: #fff;
        border-radius: 8px;
        padding: 10px 0px;
      }
    }
  }
`;

export const PointHistoryContainerMobile = styled.div<any>`
  .history-list {
    background: #363131;
    border-radius: 10px;
    border: 1px solid #494443;
    color: #928c8c;
    height: 505px;
    overflow-x: auto;
    padding: 10px;
    padding-top: 0;

    .history-item {
      padding: 4px 20px;
      border-radius: 8px;
      background: #fff;
      margin-top: 10px;

      .history-item__point {
        display: flex;
        justify-content: space-between;

        .point-item {
          display: flex;
          align-items: center;

          .point-item__title {
            font-size: 14px;
            font-weight: 700;
            margin-right: 10px;
          }
        }
      }

      .history-item__quantity {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #d1d5db;
        padding-top: 5px;
        margin-top: 5px;

        .quantity-item {
          &__title {
            font-size: 14px;
            font-weight: 700;
            margin-right: 10px;
          }

          &__value {
            font-size: 14px;
            font-weight: 400;
          }

          &__date {
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }
`;

export default PointHistory;
