import { updateRoomConnectedRequest } from 'app/apis/gift';
import TRTC from 'trtc-js-sdk';
import { useNavigate } from 'react-router-dom';
import {
  checkLikeRequest,
  getDetailRoomRequest,
  getRoomLink,
  joinRoomRequest,
  leaveRoomRequest,
  likeRequest,
  postRoomCloseRequest,
  postRoomInitialRequest,
  postRoomStartRequest,
  putIdVideoTagRequest,
  unLikeRequest,
  submitPointJoinRoomRequest,
  checkUserViewLiveRequest,
} from 'app/apis/profile';
import { useMqtt } from 'app/hooks';
import { useProfileSlice } from 'app/pages/Profile/slice';
import { selectProfile } from 'app/pages/Profile/slice/selectors';
import { actionsToast } from 'app/pages/ToastPage/slice';
import { SDKAPPID } from 'configs';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { _log } from 'utils/_log';
import { beautyOperationUpload, exitFullscreen } from 'utils/utils';
import { selectAuth } from '../../AuthPage/slice/selectors';
import { useStreamSlice } from '../slice';
import { selectStream } from '../slice/selectors';
import { RoomAllowanceStatus } from 'utils/constants';

(window as any).mobileCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || (window as any).opera);
  return check;
};

const useInit = () => {
  const navigate = useNavigate();
  let routeId = useParams();
  const dispatch = useDispatch();
  let id;
  let locationParam;

  if (typeof window !== 'undefined') {
    id = decodeURIComponent(routeId?.id || '');
    locationParam = decodeURIComponent(window.location.pathname);
  } else {
    id = null;
  }

  const audience = 'audience';
  const anchor = 'anchor';
  const video = true;
  const audio = true;
  const mode = 'live';
  const [useStringRoomID, setUseStringRoomID] = useState<any>(false);
  const [shareRTC, setShareRTC] = useState<any>(null);
  const sendButton = useRef<any>(null);
  const [receiveStatus, setReceiveStatus] = useState(false);
  const [full, setFull] = useState(false);
  const [totalOfLike, setTotalOfLike] = useState(0);
  const [totalOfViewer, setTotalOfViewer] = useState(0);
  const [liked, setLiked] = useState(false);
  const [isMobileLive, setIsMobileLive] = useState(false);
  const [userID, setUserID] = useState<any>('');
  const [roomID, setRoomID] = useState<any>('');
  const [cameraID, setCameraID] = useState<any>('default');
  const [microphoneID, setMicrophoneID] = useState<any>('');
  const [isLoading, setIsLoading] = useState(false);
  const [messageLists, setMessageLists] = useState<any>([]);
  const [inputMessage, setInputMessage] = useState<any>('');
  const [upLinkValue, setUpLinkValue] = useState(null);
  const [isShowScreenLive, setIsShowScreenLive] = useState<any>(true);
  const [isMuteVideo, setIsMuteVideo] = useState<any>(true);

  const [isTheater, setIsTheater] = useState<any>(false);
  const [lowConnect, setLowConnect] = useState<boolean>(false);
  const [liveStatus, setLiveStatus] = useState<string>('0');
  const [isFirst5Seconds, setIsFirst5Seconds] = useState(true);
  const [runFirstTimeWithCondition, setRunFirstTimeWithCondition] =
    useState(false);

  const [isJoined, setIsJoined] = useState<any>(false);
  const [isPublished, setIsPublished] = useState<any>(false);
  const [RTC, setRTC] = useState<any>(null);
  const [chatRTC, setChatRTC] = useState<any>(null);
  const [localStreamConfig, setLocalStreamConfig] = useState<any>(null);
  const [remoteStreamConfigList, setRemoteStreamConfigList] = useState<any>([]);
  const [defaultMedia, setDefaultMedia] = useState({
    muteVideo: true,
    muteAudio: true,
    facingMode: 'user',
  });
  const [idVideo, setIdVideo] = useState({
    divId: '',
    audioId: '',
    videoId: '',
  });
  const [roomAllowanceStatus, setRoomAllowanceStatus] = useState('');
  const [isUserAllowViewLive, setIsUserAllowViewLive] = useState(true);
  const [isShowModalSubmitViewLive, setIsShowModalSubmitViewLive] =
    useState(false);
  const [requirePoint, setRequirePoint] = useState<any>(0);
  const [showPointPopup, setShowPointPopup] = useState<boolean>(false);

  const { currentUser } = useSelector(selectAuth);
  const { currentPerson } = useSelector(selectProfile);
  const { streamConfig, roomLists, myRoomLists, generateRoom } =
    useSelector(selectStream);
  const [fetchRoomLinkError, setFetchRoomLinkError] = useState(false);

  const { actions } = useStreamSlice();
  const actionProfile = useProfileSlice();

  const [userStream, setUserStream] = useState<any>(null);
  let isMySelf = !id ? true : false;

  const isVirtualCamera = (string, value) => {
    return string?.toLowerCase()?.search(value) !== -1;
  };

  const initialRoom = async () => {
    try {
      const camera = await TRTC.getCameras();
      const microphone = await TRTC.getMicrophones();

      const callInitialRoomApi = async () => {
        const res: any = await postRoomInitialRequest();
        if (res?.statusCode === 201 && res?.data) {
          setRoomID(`${res?.data}`);
        }
      };

      if (camera?.length > 0 && microphone?.length > 0) {
        if (camera?.length > 1) {
          callInitialRoomApi();
        } else {
          camera?.map(async c => {
            if (
              isVirtualCamera(c?.label, 'obs') ||
              isVirtualCamera(c?.label, 'virtual')
            ) {
              toast.error(
                '使用されているカメラまたはマイクが無効です。 デバイスを確認してください',
              );
            } else {
              callInitialRoomApi();
            }
          });
        }
      } else {
        toast.error('カメラやマイクがありません。デバイスを確認してください');
      }
    } catch (err) {
      _log('initialRoom error: ', err, 'error');
    }
  };

  const putIdVideoTag = async idVideo => {
    try {
      const res = await putIdVideoTagRequest({
        ...idVideo,
        room_id: roomID,
      });
    } catch (err) {
      _log('putIdVideoTag error: ', err, 'error');
    }
  };

  const stopRoom = async roomID => {
    try {
      await postRoomCloseRequest({ room_id: roomID });
      setRoomID('');
    } catch (error) {
      _log('stopRoom error: ', error, 'error');
    }
  };

  const startRoom = async roomID => {
    try {
      setIsLoading(true);
      const res: any = await postRoomStartRequest({
        room_id: roomID,
        width: (window as any).mobileCheck() ? 'mobileWidth' : 'desktopWidth',
        height: (window as any).mobileCheck()
          ? 'mobileHeight'
          : 'desktopHeight',
      });

      if (res?.statusCode === 201) {
        // await RTC.handleJoin();
        // await RTC.handlePublish(defaultMedia.facingMode);
        setIsLoading(false);
      }
    } catch (err: any) {
      setIsLoading(false);
      if (err?.response.data.code === 19) {
        dispatch(
          actionsToast.openToast({
            message: err?.response?.data?.message,
            type: 'error',
          }),
        );
      }
    }
  };

  const handleJoin = async () => {
    setMessageLists([]);

    if (isMySelf) {
      await initialRoom();
    } else {
      id && fetchRoomLink();
    }
  };

  const handlePublish = async () => {
    await RTC.handlePublish(defaultMedia.facingMode);
  };

  const handleUnPublish = async () => {
    await RTC.handleUnPublish();
  };

  useEffect(() => {
    if (isMySelf) {
      if (RTC && roomID) {
        const JoinFunction = async () => {
          Promise.resolve()
            .then(async () => {
              await RTC.handleJoin();
              await RTC.handlePublish(defaultMedia.facingMode);
            })
            .then(async () => {
              // Start Room Api calls in BaseRTC
              // await startRoom();
            });
        };

        JoinFunction();
      }
    } else {
      if (RTC && roomID) {
        setIsLoading(true);
        joinRoomRequest({ id: roomID })
          .then(res => {
            if (res) {
              setIsLoading(false);
            }
          })
          .catch(err => {
            _log('useInit error: ', err, 'error');
            setIsLoading(false);
          });
        RTC.handleJoin();
      }
    }
  }, [RTC && roomID, isMySelf]);

  const handleLeave = async () => {
    if (!isMySelf) {
      try {
        if (roomID) {
          const res = await leaveRoomRequest({ id: roomID });
          if (res) {
            setIsLoading(false);
          }
        }
      } catch (err) {
        _log('handleLeave error: ', err, 'error');
        setIsLoading(false);
      }
    } else {
      // stopRoom(roomID);
    }

    setUpLinkValue(null);

    try {
      RTC.handleLeave();
      RTC.beautyPlugin && RTC.beautyPlugin.destroy();
    } catch (error) {
      console.error(error);
    }
  };

  const handleLeaveById = async id => {
    if (!isMySelf) {
      try {
        if (id) {
          const res = await leaveRoomRequest({ id: id });
          if (res) {
            setIsLoading(false);
            setIsShowScreenLive(true);
          }
        }
      } catch (err) {
        _log('handleLeaveById error: ', err, 'error');
        setIsLoading(false);
      }
    } else {
      // stopRoom(id);
    }

    setUpLinkValue(null);

    try {
      RTC.handleLeave();
      RTC.beautyPlugin && RTC.beautyPlugin.destroy();
    } catch (error) {
      console.error(error);
    }
  };

  const setState = (type, value) => {
    switch (type) {
      case 'join':
        setIsJoined(value);
        break;
      case 'publish':
        setIsPublished(value);
        break;
      default:
        break;
    }
  };

  const addUser = (userID, streamType) => {
    if (streamType === 'local') {
      setLocalStreamConfig({
        stream: null,
        streamType,
        userID,
        hasAudio: false,
        hasVideo: false,
        mutedAudio: false,
        mutedVideo: false,
        shareDesk: false,
        theater: false,
        audioVolume: 0,
      });
    } else {
      setRemoteStreamConfigList(preList => {
        const newRemoteStreamConfigList =
          preList?.length > 0
            ? preList.filter(streamConfig => streamConfig.userID !== userID)
            : [];
        newRemoteStreamConfigList.push({
          stream: null,
          streamType: 'main',
          userID,
          hasAudio: false,
          hasVideo: false,
          mutedAudio: false,
          mutedVideo: false,
          subscribedAudio: false,
          subscribedVideo: false,
          resumeFlag: false,
          audioVolume: 0,
        });
        return newRemoteStreamConfigList;
      });
    }
  };

  const addStream = stream => {
    const streamType = stream.getType();
    const userID = stream.getUserId();
    switch (streamType) {
      case 'local':
        setLocalStreamConfig({
          stream,
          streamType,
          userID,
          hasAudio: audio,
          hasVideo: video,
          mutedAudio: defaultMedia.muteAudio,
          mutedVideo: defaultMedia.muteVideo,
          facingMode: defaultMedia.facingMode,
          shareDesk: false,
          theater: false,
          audioVolume: 0,
        });
        break;
      default: {
        setRemoteStreamConfigList(preList => {
          const newRemoteStreamConfigList =
            preList?.length > 0
              ? preList.filter(
                  streamConfig =>
                    !(
                      streamConfig.userID === userID &&
                      streamConfig.streamType === streamType
                    ),
                )
              : [];
          newRemoteStreamConfigList.push({
            stream,
            streamType,
            userID,
            hasAudio: stream.hasAudio(),
            hasVideo: stream.hasVideo(),
            mutedAudio: false,
            mutedVideo: false,
            subscribedAudio: true,
            subscribedVideo: true,
            resumeFlag: false,
            audioVolume: 0,
          });
          return newRemoteStreamConfigList;
        });
        break;
      }
    }
  };

  const updateStream = stream => {
    if (stream.getType() === 'local') {
      setLocalStreamConfig({
        ...localStreamConfig,
        stream,
        hasAudio: stream.hasAudio(),
        hasVideo: stream.hasVideo(),
      });
    } else {
      setRemoteStreamConfigList(preList =>
        preList?.map(config =>
          config.stream === stream
            ? {
                ...config,
                stream,
                hasAudio: stream.hasAudio(),
                hasVideo: stream.hasVideo(),
              }
            : config,
        ),
      );
    }
  };

  const updateStreamConfig = (userID, type, value) => {
    if (localStreamConfig && localStreamConfig.userID === userID) {
      const config: any = {};
      switch (type) {
        case 'audio-volume':
          if (localStreamConfig.audioVolume === value) {
            break;
          }
          config.audioVolume = value;
          break;
        case 'share-desk':
          config.shareDesk = value;
          break;
        case 'uplink-network-quality':
          config.uplinkNetworkQuality = value > 0 ? 6 - value : value;
          setUpLinkValue(config.uplinkNetworkQuality);

          break;
        case 'downlink-network-quality':
          if (!isMySelf) {
            if (isFirst5Seconds) {
              config.downlinkNetworkQuality = 1;
              setTimeout(() => {
                setIsFirst5Seconds(false);
              }, 5000);
            } else {
              config.downlinkNetworkQuality = value > 0 ? 6 - value : value;
            }

            setTimeout(() => {
              if (!isFirst5Seconds && config.downlinkNetworkQuality === 0) {
                setLowConnect(true);
              } else {
                setLowConnect(false);
              }
            }, 10000);
          }

          break;
        default:
          break;
      }
      setLocalStreamConfig(prevConfig => ({
        ...prevConfig,
        ...config,
      }));
      return;
    }
    const config: any = {};
    switch (type) {
      case 'mute-audio':
        config.mutedAudio = true;
        break;
      case 'unmute-audio':
        config.mutedAudio = false;
        break;
      case 'mute-video':
        config.mutedVideo = true;
        setIsMuteVideo(true);
        break;
      case 'unmute-video':
        config.mutedVideo = false;
        setIsMuteVideo(false);
        break;
      case 'resume-stream':
        config.resumeFlag = true;
        break;
      case 'audio-volume':
        if (config.audioVolume === value) {
          break;
        }
        config.audioVolume = value;
        break;
      default:
        break;
    }
    setRemoteStreamConfigList(preList =>
      preList.map(item =>
        item.userID === userID ? { ...item, ...config } : item,
      ),
    );
  };

  const removeStream = stream => {
    const streamType = stream.getType();
    const userID = stream.getUserId();
    switch (streamType) {
      case 'local':
        setLocalStreamConfig(prevConfig => ({
          ...prevConfig,
          hasAudio: false,
          hasVideo: false,
        }));
        break;
      default: {
        setRemoteStreamConfigList(preList =>
          preList?.map(streamConfig =>
            streamConfig.userID === userID &&
            streamConfig.streamType === streamType
              ? {
                  ...streamConfig,
                  hasAudio: false,
                  hasVideo: false,
                  subscribedAudio: false,
                  subscribedVideo: false,
                }
              : streamConfig,
          ),
        );
        break;
      }
    }
  };

  const removeUser = (userID, streamType) => {
    if (streamType === 'local') {
      setLocalStreamConfig(null);
      setRemoteStreamConfigList([]);
    } else {
      setRemoteStreamConfigList(preList =>
        preList.filter(streamConfig => streamConfig.userID !== userID),
      );
    }
  };

  const handleLocalChange = async data => {
    switch (data.name) {
      case 'video':
        if (!localStreamConfig.mutedVideo) {
          RTC.muteVideo();
        } else {
          RTC.unmuteVideo();
        }
        setLocalStreamConfig({
          ...localStreamConfig,
          mutedVideo: !localStreamConfig.mutedVideo,
        });
        break;
      case 'theater':
        setIsTheater(!isTheater);
        setLocalStreamConfig({
          ...localStreamConfig,
          theater: !localStreamConfig.theater,
        });
        break;
      case 'audio':
        if (!localStreamConfig.mutedAudio) {
          RTC.muteAudio();
        } else {
          RTC.unmuteAudio();
        }
        setLocalStreamConfig({
          ...localStreamConfig,
          mutedAudio: !localStreamConfig.mutedAudio,
        });
        break;
      case 'shareDesk':
        if (!localStreamConfig.shareDesk) {
          await shareRTC.handleJoin();
        } else {
          await shareRTC.handleLeave();
        }
        setLocalStreamConfig({
          ...localStreamConfig,
          shareDesk: !localStreamConfig.shareDesk,
        });
        break;
      default:
        break;
    }
  };

  const handleRemoteChange = async data => {
    const remoteStream = data.stream;
    const config = remoteStreamConfigList?.find(
      config => config.stream === remoteStream,
    );
    switch (data.name) {
      case 'theater':
        if (full) exitFullscreen();

        setIsTheater(!isTheater);
        setRemoteStreamConfigList(preList =>
          preList?.map(config =>
            config.stream === remoteStream
              ? {
                  ...config,
                  theater: !config.theater,
                }
              : config,
          ),
        );
        break;
      case 'subscribedVideo':
        await RTC.handleSubscribe(remoteStream, {
          video: !config.subscribedVideo,
          audio: config.subscribedAudio,
        });

        setRemoteStreamConfigList(preList =>
          preList?.map(config =>
            config.stream === remoteStream
              ? {
                  ...config,
                  subscribedVideo: !config.subscribedVideo,
                }
              : config,
          ),
        );
        break;
      case 'subscribedAudio':
        await RTC.handleSubscribe(remoteStream, {
          video: config.subscribedVideo,
          audio: config.subscribedAudio,
        });
        setRemoteStreamConfigList(preList =>
          preList?.map(config =>
            config.stream === remoteStream
              ? {
                  ...config,
                  subscribedAudio: !config.subscribedAudio,
                }
              : config,
          ),
        );
        break;
      case 'resumeFlag':
        await RTC.resumeStream(config.stream);
        setRemoteStreamConfigList(preList =>
          preList?.map(config =>
            config.stream === remoteStream
              ? {
                  ...config,
                  resumeFlag: false,
                }
              : config,
          ),
        );
        break;
      default:
        break;
    }
  };

  const changeBeauty = (number, type) => {
    if (!RTC || !RTC.beautyPlugin) {
      return;
    }

    if (RTC.beautyPlugin) {
      switch (type) {
        case 'beauty':
          RTC.beautyPlugin.setBeautyParam({ beauty: number / 100 });
          break;
        case 'brightness':
          RTC.beautyPlugin.setBeautyParam({ brightness: number / 100 });
          break;
        case 'ruddy':
          RTC.beautyPlugin.setBeautyParam({ ruddy: number / 100 });
          break;
      }
      beautyOperationUpload(SDKAPPID, type);
    }
  };

  const handleCheckLike = async () => {
    const res = await checkLikeRequest({ id: roomID });
    if (res) {
      return res?.data;
    }
    return false;
  };

  const handleCheckUserViewLive = async () => {
    const res = await checkUserViewLiveRequest(roomID);

    if (res) {
      if (res.data === RoomAllowanceStatus.UNPAID) {
        setIsUserAllowViewLive(false);
        setIsShowModalSubmitViewLive(true);
        return;
      }
      setRoomAllowanceStatus(res.data);
    }
  };

  const handleLike = async () => {
    const check = await handleCheckLike();

    if (!check) {
      likeRequest({ id: roomID }).then(res => {
        setLiked(!check);
      });
    } else {
      unLikeRequest({ id: roomID }).then(res => {
        setLiked(!check);
      });
    }
  };

  const fetchRoomLink = () => {
    getRoomLink({ username: id })
      .then((res: any) => {
        const { data } = res;
        if (data) {
          setRoomID(String(data));
        } else {
          setRoomID('');
          dispatch(
            actionsToast.openToast({
              message:
                'ただいま配信準備中です。配信までもうしばらくお待ち下さい。',
              type: 'error',
            }),
          );
          setFetchRoomLinkError(true);
        }
      })
      .catch(err => {
        dispatch(
          actionsToast.openToast({
            message:
              'ただいま配信準備中です。配信までもうしばらくお待ち下さい。',
            type: 'error',
          }),
        );
        setFetchRoomLinkError(true);
      });
  };

  useMqtt({
    topicName: `room/info/${roomID}`,
    callback: onReceiveStreamInformation,
  });

  useMqtt({
    topicName: `room/status/${roomID}`,
    callback: onReceiveStreamStatus,
  });

  useMqtt({
    topicName: `user/logout/${currentUser?.id}`,
    callback: onLogout,
  });

  useMqtt({
    topicName: `user/current_room/${currentUser?.id}`,
    callback: onGetUserInfor,
    dependencies: [roomID],
  });

  useMqtt({
    topicName: `user/${currentUser?.id}/join_room/${roomID}`,
    callback: onGetAllowUserViewLive,
    dependencies: [roomID, roomAllowanceStatus],
  });

  function onReceiveStreamInformation(message: Buffer) {
    try {
      const msg = JSON.parse(message.toString());

      const { data } = msg;

      const dataParse = JSON.parse(data);

      setTotalOfLike(dataParse?.current_like);
      setTotalOfViewer(dataParse?.current_viewer);
      // setTotalOfViewer(20 + Number(dataParse?.current_viewer));
    } catch (error) {
      toast.error('配信のリストが取得できません');
    }
  }

  function onReceiveStreamStatus(message: Buffer) {
    try {
      const msg = JSON.parse(message.toString());

      const { data } = msg;

      const dataParse = JSON.parse(data);

      setLiveStatus(dataParse.status);
    } catch (error) {
      toast.error('配信情報が取得できません');
    }
  }

  function onLogout(message: Buffer) {
    try {
      const msg = JSON.parse(message.toString());
      const { data } = msg;
      const { logout } = JSON.parse(data);
      if (logout) {
        handleLeave();
      }
    } catch (error) {
      _log('onLogout error: ', error, 'error');
    }
  }

  function onGetUserInfor(message: Buffer) {
    try {
      const msg = JSON.parse(message.toString());

      const { data } = msg;

      const dataParse = JSON.parse(data);

      setIsShowScreenLive(dataParse.room_id.toString() === roomID);
    } catch (error) {
      // toast.error('配信情報が取得できません');
    }
  }

  function onGetAllowUserViewLive(message: Buffer) {
    try {
      const msg = JSON.parse(message.toString());

      const { data } = msg;

      if (
        !data.is_charge &&
        (roomAllowanceStatus === RoomAllowanceStatus.UNPAID ||
          roomAllowanceStatus === RoomAllowanceStatus.FIRST_TIME)
      ) {
        setIsUserAllowViewLive(false);
        setIsShowModalSubmitViewLive(true);
      }
    } catch (error) {}
  }

  const submitPointViewLive = async () => {
    try {
      if (currentUser?.point < requirePoint) {
        setShowPointPopup(true);
      } else {
        const res = await submitPointJoinRoomRequest({
          room_id: roomID && Number(roomID),
        });

        if (res) {
          navigate(0);
        }
      }
    } catch (error: any) {
      toast.error('Submitting points to watch live failed');
    }
  };

  const handleCancelModalViewLive = () => {
    setIsShowModalSubmitViewLive(false);
  };

  useEffect(() => {
    dispatch(actions.getRoomList({}));
    dispatch(actions.getMyRoomList({}));
  }, []);

  useEffect(() => {
    if (localStreamConfig?.stream && !roomID) {
      if (isMySelf && myRoomLists?.length > 0) {
        stopRoom(myRoomLists[0]?.id);
      }
    }
  }, [roomID, myRoomLists, isMySelf, localStreamConfig]);

  useEffect(() => {
    if (!isMySelf && roomID) {
      const firstCheckLike = async () => {
        const check = await handleCheckLike();

        setLiked(check);
      };

      firstCheckLike();
      handleCheckUserViewLive();
    }
  }, [roomID, isMySelf]);

  // real-time detail live stream
  useEffect(() => {
    if (roomID && roomID !== '') {
      try {
        getDetailRoomRequest({ id: roomID }).then(res => {
          const { data } = res;
          setTotalOfLike(data?.current_like);
          setTotalOfViewer(data?.current_viewer);
          setRequirePoint(data?.require_point);
          // setTotalOfViewer(20 + Number(data?.current_viewer));

          if (data) {
            const { width, height } = data;

            if (width === 'mobileWidth' && height === 'mobileHeight') {
              setIsMobileLive(true);
            } else {
              setIsMobileLive(false);
            }
          }
        });
      } catch (error) {
        _log('useInit error 2: ', error, 'error');
      }
    }
  }, [roomID]);

  useEffect(() => {
    if (id) {
      fetchRoomLink();
    }
  }, [id]);

  useEffect(() => {
    if (isMySelf && idVideo?.videoId) {
      putIdVideoTag(idVideo);
    }
  }, [idVideo, isMySelf]);

  useEffect(() => {
    if (lowConnect && liveStatus !== '3') {
      toast.warn(
        '配信者はインターネット 接続が中断されています。少々お待ち下さい',
      );
    }
  }, [lowConnect, liveStatus]);

  useEffect(() => {
    if (currentUser) {
      setUserID(`${currentUser?.id}`);
    }
  }, [currentUser]);

  useEffect(() => {
    if (localStreamConfig?.stream) {
      Promise.resolve()
        .then(() => {
          defaultMedia.muteVideo && RTC.muteVideo();
        })
        .then(() => {
          defaultMedia.muteAudio && RTC.muteAudio();
        });
      setRunFirstTimeWithCondition(true);
    }
  }, [
    RTC,
    runFirstTimeWithCondition,
    localStreamConfig?.stream,
    defaultMedia.muteAudio,
    defaultMedia.muteVideo,
  ]);

  if (TRTC && !microphoneID) {
    const getDeviceList = async () => {
      let deviceList: any = await TRTC.getMicrophones();

      if (deviceList[0]?.deviceId) {
        setMicrophoneID(deviceList[0]?.deviceId);
      }
    };

    getDeviceList();
  }

  return {
    startRoom,
    changeBeauty,
    handleJoin,
    handleLeave,
    handleLocalChange,
    handlePublish,
    handleRemoteChange,
    handleUnPublish,
    isMySelf,
    setState,
    addUser,
    addStream,
    updateStream,
    updateStreamConfig,
    removeStream,
    removeUser,
    userStream,
    setUserStream,
    actions,
    audience,
    anchor,
    dispatch,
    id,
    locationParam,
    video,
    audio,
    mode,
    useStringRoomID,
    setUseStringRoomID,
    shareRTC,
    setShareRTC,
    sendButton,
    userID,
    setUserID,
    roomID,
    setRoomID,
    cameraID,
    setCameraID,
    microphoneID,
    setMicrophoneID,
    isLoading,
    setIsLoading,
    messageLists,
    setMessageLists,
    inputMessage,
    setInputMessage,
    isTheater,
    setIsTheater,
    isJoined,
    setIsJoined,
    isPublished,
    setIsPublished,
    RTC,
    setRTC,
    localStreamConfig,
    setLocalStreamConfig,
    remoteStreamConfigList,
    setRemoteStreamConfigList,
    currentUser,
    actionProfile,
    currentPerson,
    streamConfig,
    receiveStatus,
    setReceiveStatus,
    full,
    setFull,
    myRoomLists,
    roomLists,
    totalOfLike,
    totalOfViewer,
    handleLike,
    liked,
    idVideo,
    setIdVideo,
    stopRoom,
    isShowScreenLive,
    setIsShowScreenLive,
    handleLeaveById,
    isMobileLive,
    chatRTC,
    setChatRTC,
    defaultMedia,
    setDefaultMedia,
    submitPointViewLive,
    isShowModalSubmitViewLive,
    handleCancelModalViewLive,
    isUserAllowViewLive,
    showPointPopup,
    setShowPointPopup,
    fetchRoomLinkError,
    requirePoint,
    isMuteVideo,
  };
};

export default useInit;
