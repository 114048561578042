import React from 'react';
import { Tooltip, Button } from 'antd';

import { breakpoints } from 'utils/media-breakpoint';
import { usePointPurchase } from './hooks';
import { PointPurchaseContainer } from './styles';

const PointPurchase = ({ setShowPointPopup, pointPakageData }: any) => {
  const {
    pointPakageSelect,
    pakageQuantity,
    pakagePrice,
    loadingButtonPurchase,
    handleChangePointPakage,
    handleChangeQuantity,
    purchasePointPackage,
    isMobile,
  } = usePointPurchase({ pointPakageData, setShowPointPopup });

  return (
    <PointPurchaseContainer breakpoints={breakpoints}>
      <div className="package-purchase-container">
        <div className="list-point-package">
          <ul>
            {pointPakageData.map(item => (
              <li
                className="gift-item"
                key={item.id}
                onClick={() => handleChangePointPakage(item)}
              >
                <div className="gift-item-box">
                  <div
                    className="content-text ellipsis-1"
                    style={{
                      color: '#928C8C',
                      fontSize: '12px',
                      fontWeight: '400',
                      textAlign: 'center',
                      marginBottom: '4px',
                      maxHeight: '18px',
                    }}
                  >
                    <Tooltip title={item.name}>{item.name}</Tooltip>
                  </div>
                  <div
                    className={`gift-image-box ${
                      pointPakageSelect.id == item.id && 'gift-selected'
                    }`}
                  >
                    <img src={item.image_url} alt="" width={60} />
                    <div className="gift-point-wrap">
                      <p className="gift-point">{item.quantity}</p>
                      <p className="gift-point-text">Points</p>
                    </div>
                  </div>
                  <p className="gift-name ellipsis-1">¥{item.price}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="point-package-price">
          <div className="point-quantity">
            <label className="point-label">購入数: </label>
            <input
              type="number"
              className="point-input"
              value={pakageQuantity}
              onChange={handleChangeQuantity}
            />
          </div>
          <div className="point-quantity">
            <label className="point-label">価格: </label>
            <input
              type="text"
              className="point-input"
              value={new Intl.NumberFormat().format(
                pakagePrice * pakageQuantity,
              )}
              disabled
            />
          </div>
        </div>
        <div className="point-button">
          <button onClick={() => setShowPointPopup(false)}>キャンセル</button>

          <Button
            type="primary"
            className="purchase-button"
            size="large"
            loading={loadingButtonPurchase}
            onClick={purchasePointPackage}
          >
            {/* Purchase */}
            購入する
          </Button>
        </div>
      </div>
    </PointPurchaseContainer>
  );
};

export default PointPurchase;
