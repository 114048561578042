import styled from 'styled-components';

export const Wrapper = styled.div<any>`
  position: absolute;
  inset: 0;
  color: #000;
  overflow: hidden;

  & > div {
    width: 100%;
    height: 100%;
  }

  .anticon {
    font-size: 24px;
    height: 16px;
    color: #fff;
    cursor: pointer;
  }

  .ant-image {
    img {
      display: flex;
      margin: auto;
    }
  }

  .shadow-orange {
    position: absolute;
    z-index: 1;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 10%;
    border-radius: ${props =>
      props.isTheater || props.isFull
        ? '0px'
        : props?.isMySelf
        ? '0px'
        : '20px'};
    background: linear-gradient(180deg, rgba(162, 23, 73, 0) 0%, #a21749 100%);

    @media only screen and (max-width: 1729px) {
      bottom: 0px;
    }
    @media only screen and (max-width: ${props => props.breakpoints.mobile}) {
      border-radius: ${props =>
        props?.isMySelf || props.isFull ? '0px' : '16px'};
    }
  }

  .shadow-orange-top {
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 75%;
    border-radius: ${props =>
      props?.isMySelf || props.isFull ? '0px' : '16px'};
    background: linear-gradient(
      to bottom,
      #a21749 0%,
      rgba(235, 125, 23, 0) 12%,
      rgba(235, 125, 23, 0) 100%
    );

    @media only screen and (max-width: 1729px) {
      top: 0px;
    }
  }

  .drop-heart {
    position: absolute;
    z-index: 1;
    bottom: 58px;
    right: 15px;
    background-color: #eb7d17;
    width: max-content;
    padding: 10px;
    border-radius: 7px;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    @media only screen and (max-width: ${props => props.breakpoints.mobile}) {
      bottom: 110px;
    }

    &:hover {
      opacity: 0.8;
      transition: 0.3s;
    }
  }

  .drop-gift {
    bottom: 58px;
  }

  .user-infor {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 10px;

    h2 {
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      margin: 0;
    }

    .ant-space-item p {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .view-info-container {
    width: 100%;
    position: absolute;
    bottom: 13px;
    left: 0;
    padding-inline: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
  }

  .view-info {
    display: flex;
    align-items: center;
    gap: 12px;

    p {
      color: #fff;
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 6px;
    }

    .live-text {
      align-items: center;
      padding: 2px 10px;
      background: #ff347d;
      color: #fff;
      border-radius: 16px;
      font-size: 12px;
      font-weight: 600;

      span {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background: #fff;
      }
    }
  }

  .view-info.self {
    gap: 21px;
  }

  .settings-container {
    display: flex;
    gap: 20px;
    align-items: center;
    position: absolute;
    z-index: 1;
    right: 18px;
    bottom: 2px;

    .ant-image {
      cursor: pointer;
    }
  }
`;
