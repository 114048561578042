declare global {
  interface Window {
    MSStream: any;
    WebKitMediaSource: any;
    WebKitSourceBuffer: any;
  }
}

export const NAV: Navigator | null =
  typeof window !== 'undefined' ? window.navigator : null;

export const UA: string | null = NAV ? NAV.userAgent.toLowerCase() : null;

export const IS_IPAD = UA ? /ipad/i.test(UA) && !window.MSStream : false;

export const IS_IPHONE = UA ? /iphone/i.test(UA) && !window.MSStream : false;

export const IS_IPOD = UA ? /ipod/i.test(UA) && !window.MSStream : false;

export const IS_IOS = UA
  ? /ipad|iphone|ipod/i.test(UA) && !window.MSStream
  : false;

export const IS_ANDROID = UA ? /android/i.test(UA) : false;

export const IS_EDGE = NAV
  ? 'msLaunchUri' in NAV && !('documentMode' in document)
  : false;

export const IS_CHROME = UA ? /chrome/i.test(UA) : false;

export const IS_FIREFOX = UA ? /firefox/i.test(UA) : false;

export const IS_SAFARI = UA ? /safari/i.test(UA) && !IS_CHROME : false;

export const IS_STOCK_ANDROID = UA
  ? /^mozilla\/\d+\.\d+\s\(linux;\su;/i.test(UA)
  : false;

export enum RoomAllowanceStatus {
  FIRST_TIME = 'firstTime',
  PAID = 'paid',
  UNPAID = 'unpaid',
}
