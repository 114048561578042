/**
 *
 * MetaSelect
 *
 */
import { Form, Select } from 'antd';
import React, { memo } from 'react';
import styled from 'styled-components/macro';

interface Props {
  className?: string;
  name: string;
  rules?: any[];
  label?: string;
  options: any[];
  placeholder?: string;
  defaultValue?: string;
  boldLabel?: boolean;
  inform?: boolean;
  required?: boolean;
  disabled?: boolean;
  style?: any;
  onChange?: any;
  value?: any;
  mode?: any;
  tagRender?: any;
  id?: any;
  status?: '' | 'warning' | 'error';
  isRequired?: boolean;
}

export const MetaSelect = memo((props: Props) => {
  const {
    name,
    label,
    options,
    placeholder,
    rules,
    className,
    boldLabel,
    inform = true,
    disabled = false,
    required,
    style,
    onChange,
    value,
    mode,
    tagRender,
    status = '',
    defaultValue,
    isRequired,
  } = props;

  return (
    <FormItemWrapper
      name={name}
      label={
        isRequired ? (
          label ? (
            <p>
              {label} <b style={{ color: '#c0392b' }}>*</b>
            </p>
          ) : null
        ) : label ? (
          <p>{label}</p>
        ) : null
      }
      rules={rules}
      boldLabel={boldLabel}
      inform={inform ? inform.toString() : undefined}
      required={required}
      validateTrigger="onBlur"
    >
      <SelectCustom
        showSearch
        mode={mode || 'single'}
        placeholder={placeholder || `Select a ${name}`}
        optionFilterProp="children"
        disabled={disabled}
        filterOption={(input, option) =>
          (option?.label ?? '')
            .toLowerCase()
            .trim()
            .includes(input.trim().toLowerCase())
        }
        status={status}
        options={options}
        className={className}
        onSearch={value => value.trim()}
        onChange={onChange}
        style={style}
        value={value}
        tagRender={tagRender}
        defaultValue={defaultValue}
      />
    </FormItemWrapper>
  );
});

const FormItemWrapper = styled(Form.Item)<any>`
  width: 100%;
  margin-bottom: 10px;

  .ant-input-affix-wrapper-disabled {
    background: var(--color-gray-4) !important;
  }

  .ant-form-item-label {
    align-items: flex-start !important;
    padding-bottom: 4px;
    label {
      font-size: 14px;
      color: #928c8c;
      font-weight: 400;
    }
  }
  label {
    font-size: 14px;
    color: #928c8c;
    font-weight: 400;
    &::after {
      content: ${props => (props.label === ' ' ? '" "' : '":"')} !important;
    }
  }

  .ant-form-item-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 4px;
    label {
      font-size: 14px;
      color: #928c8c;
      font-weight: 400;
    }
  }
  .ant-form-item-explain-error {
    color: #e10000 !important;
  }
  .ant-form-item-explain-error:first-child {
    display: block;
  }

  .ant-input-status-error,
  .ant-input-affix-wrapper-status-error {
    border-color: #e10000 !important;
  }
`;

const SelectCustom = styled(Select)`
  &.ant-select-status-error {
    .ant-select-selector {
      border: 1px solid #e10000 !important;
    }
  }

  .ant-select-selection-item {
    height: 24px;
    border-radius: 16px;
    padding: 2px 20px;
    font-size: 12px;
    font-weight: 400;
    margin-right: 10px;
  }

  .ant-select-arrow {
    color: #1f2937;
  }

  input {
    /* padding: 6px 20px 6px 10px !important; */
    border-radius: 10px;
    border-color: #d1d5db;
    height: 44px;
  }

  .ant-select-selection-placeholder {
    padding: 6px 20px 6px 10px !important;
    color: #d1d5db !important;
  }

  .ant-select-selector {
    padding: 6px 20px 6px 22px !important;
    border-radius: 10px !important;
    border-color: #5f5858 !important;
    min-height: 44px !important;
    height: max-content !important;
    background: #494443 !important;

    &:hover {
      border-color: #eb7d17 !important;
    }

    &:focus {
      border-color: #eb7d17 !important;
      box-shadow: 0px 0px 14px 0px #ff00001a;
    }

    &::placeholder {
      color: #d1d5d8;
      font-weight: 400;
      font-size: 14px;
    }

    &.inlines {
      border: 1px solid #eb7d17 !important;
      border-radius: 8px;
      background: #fff;
    }

    input {
      border-color: #fff;
      color: #fff !important;
    }

    .ant-select-selection-placeholder {
      color: #5f5858 !important;
    }

    .ant-tag,
    .ant-tag-close-icon {
      color: #fff;
    }
  }
  .ant-select-selection-search {
    margin-inline-start: 0;
    z-index: 2;
  }
  .ant-select-selection-search-input {
    height: 100% !important;
  }

  .ant-select-arrow .anticon > svg {
    color: #928c8c;
  }

  @media only screen and (max-width: 703px) {
    display: flex;
    flex-direction: column;
    .ant-select-selector {
      min-height: 32px !important;
      height: max-content !important;
    }
  }
`;
