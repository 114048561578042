import { useState } from 'react';
import Cookies from 'js-cookie';
import { deleteAllCookies } from 'utils/common';
import { STORAGE_KEY } from 'shared/constants/storageKey';

export default function useToken() {
  const getToken = () => {
    return Cookies.get(STORAGE_KEY.ACCESS_TOKEN);
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken: string) => {
    Cookies.set(STORAGE_KEY.ACCESS_TOKEN, userToken);
    setToken(userToken);
  };

  const remove = () => {
    deleteAllCookies();
    Cookies.remove(STORAGE_KEY.ACCESS_TOKEN);
    setToken('');
  };

  return {
    setToken: saveToken,
    token,
    remove,
  };
}
