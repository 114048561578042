import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { RightOutlined } from '@ant-design/icons';
import { Avatar, Badge, Typography } from 'antd';

import { usePrevious } from 'app/hooks/usePrevious';
import { PusherRobot } from 'styles/image';
import { getFirstLetter } from 'utils/helpers';

import ChatInput from './ChatInput';
import { _log } from 'utils/_log';
import { allGifts, gifts } from 'app/apis/gift';

const ChatTab = ({
  messageLists,
  isMySelf,
  messageId,
  onToggleSeeAll,
  setInputMessage,
  sendButton,
  inputMessage,
  currentUser,
  setReceiveStatus,
  dispatch,
  isDisabled,
  roomID,
  giftList,
}) => {
  const [lastMessage, setLastMessage] = useState(
    messageLists?.[messageLists?.length - 1],
  );

  const prevMessage = usePrevious<string>(lastMessage);

  useEffect(() => {
    setLastMessage(messageLists?.[messageLists?.length - 1]);
  }, [JSON.parse(JSON.stringify(messageLists))]);

  const scrollBottom = () => {
    setTimeout(() => {
      const element: any = document.getElementById('chat');
      element.scrollTo(0, element.scrollHeight);
    }, 200);
    setReceiveStatus(false);
  };

  useEffect(() => {
    const element: any = document.getElementById('chat');

    if (prevMessage !== lastMessage) {
      element.scrollTo(0, element.scrollHeight);
    }
  }, [prevMessage, lastMessage]);

  return (
    <div
      className="chat-container"
      style={{
        gridTemplateRows: isMySelf && '1fr',
      }}
    >
      <div id="chat" className="top">
        {roomID && (
          <>
            {messageLists &&
              messageLists.length > 0 &&
              messageLists
                ?.sort((a, b) => a.time - b.time)
                ?.map((list, i) => {
                  /* them  */
                  if (+list?.userID !== currentUser?.id) {
                    if (list?.type !== 'notice') {
                      return (
                        <div className="box-content" key={i}>
                          <Badge dot={false}>
                            <Avatar shape="square" src={list?.avatar}>
                              {getFirstLetter(list?.nick || '')}
                            </Avatar>
                          </Badge>

                          <div className="box-content-description">
                            <Typography.Title
                              level={5}
                              style={{ color: '#928C8C' }}
                            >
                              {list?.nick}
                            </Typography.Title>

                            {/* {list?.reply?.userName && (
                            <div className="reply-message">
                              <Typography.Title level={5}>
                                {list?.reply?.userName}
                              </Typography.Title>
                              <Typography.Paragraph>
                                {list?.reply?.text}
                              </Typography.Paragraph>
                            </div>
                          )}

                          <Row
                            wrap
                            gutter={[10, 10]}
                            style={{ display: list?.images ? '' : 'none' }}
                          >
                            {list?.images &&
                              list?.images?.length > 0 &&
                              list?.images?.map((image, i) => (
                                <Col key={i} span={24 / list?.images?.length}>
                                  <Image
                                    alt=""
                                    src={image}
                                    height={150}
                                    style={{
                                      objectFit: 'cover',
                                    }}
                                  />
                                </Col>
                              ))}
                          </Row> */}

                            <div className="message-detail">
                              <Typography.Paragraph
                                ellipsis={
                                  !messageId?.includes(i)
                                    ? {
                                        rows: 7,
                                        expandable: !messageId?.includes(i),
                                      }
                                    : false
                                }
                              >
                                {list?.content}
                              </Typography.Paragraph>
                              {!messageId?.includes(i) &&
                                list?.content?.length > 300 && (
                                  <div
                                    className="btn-see-all"
                                    onClick={() => onToggleSeeAll(i)}
                                  >
                                    See All <RightOutlined />
                                  </div>
                                )}
                            </div>

                            <Typography.Text className="time-text">
                              {moment.unix(list?.time).format('HH:mm')}
                            </Typography.Text>
                          </div>
                        </div>
                      );
                    } else {
                      if (
                        list?.content &&
                        list?.content?.indexOf('has send a') > -1
                      ) {
                        const index = list?.content?.indexOf(' has send a ');
                        const index2 = list?.content?.lastIndexOf(' to ');
                        const donateSender = list?.content?.slice(0, index);
                        const donateReceiver = list?.content?.slice(index2 + 4);
                        const giftInfo = giftList?.find(
                          g =>
                            g?.name ===
                            list?.content?.slice(index + 12, index2),
                        );

                        return (
                          <div className="box-content" key={list?.type + i}>
                            <Badge dot={false}>
                              <Avatar shape="square" src={PusherRobot} />
                            </Badge>

                            <div className="box-content-description box-content-notification">
                              <Typography.Title
                                level={4}
                                className="notification-title"
                              >
                                お知らせ
                              </Typography.Title>

                              <Typography.Paragraph className="message-detail message-notification-detail">
                                <p
                                  style={{
                                    marginBottom: '0px',
                                  }}
                                >
                                  {`${donateSender} さんが  ${list?.content?.slice(
                                    index + 12,
                                    index2,
                                  )} (${
                                    giftInfo?.price || 0
                                  }p) を贈りました`}{' '}
                                  <img
                                    src={giftInfo?.image_url}
                                    style={{
                                      width: '30px',
                                      height: '30px',
                                      objectFit: 'contain',
                                    }}
                                  />
                                </p>

                                {/* <span className="notification-donater">
                                {donateSender}
                              </span>{' '}
                              has send a{' '}
                              <span className="notification-gift-name">
                                {giftInfo?.name}
                              </span>{' '}
                              to{' '}
                              <span className="notification-host">
                                {donateReceiver}
                              </span> */}
                              </Typography.Paragraph>

                              <Typography.Text className="time-text">
                                {moment.unix(list?.time).format('HH:mm')}
                              </Typography.Text>
                            </div>
                          </div>
                        );
                      }
                    }
                  } else {
                    /* our  */
                    return (
                      <div className="box-content my-message" key={i}>
                        <Badge dot={false}>
                          <Avatar shape="square" src={currentUser?.avatar_url}>
                            {getFirstLetter(list?.nick || '')}
                          </Avatar>
                        </Badge>
                        <div className="box-content-description">
                          {/* {list?.reply?.userName && (
                    <div className="reply-message">
                      <Typography.Title level={5}>
                        {list?.reply?.userName}
                      </Typography.Title>
                      <Typography.Paragraph>
                        {list?.reply?.text}
                      </Typography.Paragraph>
                    </div>
                  )} */}

                          {/* <Row
                    wrap
                    gutter={[10, 10]}
                    style={{ display: list?.images ? '' : 'none' }}
                  >
                    {list?.images &&
                      list?.images?.length > 0 &&
                      list?.images?.map((image, i) => (
                        <Col key={i} span={24 / list?.images?.length}>
                          <Image
                            alt=""
                            src={image}
                            height={150}
                            style={{
                              objectFit: 'cover',
                            }}
                          />
                        </Col>
                      ))}
                  </Row> */}

                          <div className="message-detail">
                            <Typography.Paragraph
                              ellipsis={
                                !messageId?.includes(i)
                                  ? {
                                      rows: 7,
                                      expandable: !messageId?.includes(i),
                                    }
                                  : false
                              }
                            >
                              {list?.content}
                            </Typography.Paragraph>
                            {!messageId?.includes(i) &&
                              list?.content?.length > 300 && (
                                <div
                                  className="btn-see-all"
                                  onClick={() => onToggleSeeAll(i)}
                                >
                                  See All <RightOutlined />
                                </div>
                              )}
                          </div>

                          <Typography.Text className="time-text">
                            {moment.unix(list?.time).format('HH:mm')}
                          </Typography.Text>
                        </div>
                      </div>
                    );
                  }
                })}
          </>
        )}
      </div>

      <div className="bottom relative">
        <ChatInput
          dispatch={dispatch}
          sendButton={sendButton}
          inputMessage={inputMessage}
          setInputMessage={setInputMessage}
          isDisabled={isDisabled}
          scrollBottom={scrollBottom}
        />
      </div>
    </div>
  );
};

export default ChatTab;
