import Cookies from 'js-cookie';
import { Form } from 'antd';
import { MetaButton, MetaInput } from 'app/components/Forms';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { gapi } from 'gapi-script';
import GoogleLogin, { useGoogleLogout } from 'react-google-login';
import styled from 'styled-components/macro';

import {
  IS_ANDROID,
  IS_CHROME,
  IS_IPAD,
  IS_IPHONE,
  IS_SAFARI,
} from 'utils/constants';
import { regexEmail, regexIcon } from 'utils/helpers';
import { actionsToast } from 'app/pages/ToastPage/slice';
import { AppleIcon, GoogleIcon } from 'styles/image';

const LoginForm = ({ onLoginOAuth, form, onFinish }) => {
  const [readOnly, setReadOnly] = useState(true);

  const { signOut } = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || '',
    onLogoutSuccess: () => console.log('Log out successfully'),
  });

  function getDevice() {
    if (IS_IPAD) {
      return true;
    }
    if (IS_IPHONE) {
      return true;
    }
    if (IS_ANDROID) {
      return true;
    }
    if (IS_IPAD && IS_CHROME) {
      return true;
    }
    if (IS_IPAD && IS_SAFARI) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || '',
        scope: '',
      });
    }

    gapi.load('client:auth2', start);
  });

  return (
    <FormContainer
      form={form}
      layout="vertical"
      onFinish={onFinish}
      className="form-login"
    >
      <MetaInput
        form={form}
        label="メールアドレス"
        isNormalize
        name="email"
        placeholder="メールアドレスを入力してください"
        isRequired
        rules={[
          { required: true, message: 'メールアドレスを入力してください' },
          {
            pattern: regexEmail,
            message: '無効なメールアドレスです',
          },
          {
            pattern: regexIcon,
            message: '無効な文字です',
          },
        ]}
        autoComplete="disabled"
        readOnly={!getDevice() ? readOnly : false}
        onFocus={() => setReadOnly(false)}
        onBlur={() => setReadOnly(true)}
      />
      <MetaInput
        form={form}
        label="パスワード"
        type="password"
        name="password"
        placeholder="パスワード"
        className="input-password"
        isRequired
        rules={[
          { required: true, message: 'パスワードを入力してください' },
          {
            pattern: regexIcon,
            message: '無効な文字です',
          },
        ]}
        autoComplete="off"
      />
      <Link className="forgot-password" to="/auth/forgot-password">
        パスワードをお忘れですか?
      </Link>

      <MetaButton
        type="primary"
        text="ログイン"
        className="btn-primary-pink"
        style={{ marginTop: '10px', borderRadius: '8px' }}
        onClick={() => {
          const { email, password } = form.getFieldsValue(true);
          if (email && password) {
            onFinish(form.getFieldsValue(true), null);
          } else {
            form.submit();
          }
        }}
      />

      {/* <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '10px',
          alignItems: 'center',
          margin: '20px 0px',
        }}
      >
        <div
          style={{ width: '100%', background: '#80808047', height: '1px' }}
        />
        <p style={{ color: '#808080fa' }}>or</p>
        <div
          style={{ width: '100%', background: '#80808047', height: '1px' }}
        />
      </div> */}

      {/* <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || ''}
        buttonText="Login"
        onSuccess={tokenResponse => {
          onLoginOAuth(tokenResponse);
        }}
        cookiePolicy={'single_host_origin'}
        render={renderProps => {
          return (
            <MetaButton
              type="primary"
              htmlType="submit"
              className="btn-primary-white"
              style={{ marginTop: '0px', gap: '15px', borderRadius: '8px' }}
              text="Googleでログイン"
              onClick={renderProps.onClick}
              icon={
                <img
                  style={{ width: '20px', height: '20px' }}
                  src={GoogleIcon}
                  alt="..."
                />
              }
            />
          );
        }}
      /> */}

      {/* <MetaButton
        type="primary"
        className="btn-primary-white"
        style={{ marginTop: '10px', gap: '15px', borderRadius: '35px' }}
        text="Appleでログイン"
        icon={
          <img
            className="dark-icon"
            style={{
              width: '30px',
              height: '30px',
            }}
            src={AppleIcon}
            alt="..."
          />
        }
      /> */}
    </FormContainer>
  );
};

export default LoginForm;

const FormContainer = styled(Form)<any>`
  display: flex;
  flex-direction: column;
  gap: 10px;

  > div {
    margin-bottom: 0;
  }

  .forgot-password {
    color: #928c8c;
    text-align: right;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
    width: fit-content;
    margin-left: auto;
  }

  .ant-btn-primary {
    margin-top: 10px;
  }
`;
