/**
 *
 * MetaDatePicker
 *
 */
import { DatePicker, Form } from 'antd';
import locale from 'antd/es/date-picker/locale/de_DE';
import React, { memo } from 'react';
import styled from 'styled-components/macro';

const DATE_FORMAT = 'YYYY/MM/DD';

interface Props {
  name?: string;
  label?: string;
  disabled?: boolean;
  rules?: any;
  value?: any;
  dateFormat?: string;
  onChange?: any;
  picker?: any;
  placeholder?: string;
  styles?: any;
  defaultValue?: any;
  isRequired?: boolean;
}

export const MetaDatePicker = memo((props: Props & Record<string, any>) => {
  const {
    name,
    label,
    disabled,
    rules,
    value,
    dateFormat,
    onChange,
    picker,
    placeholder,
    styles,
    defaultValue,
    isRequired = false,
    ...rest
  } = props;

  return (
    <GroupDatePicker
      label={
        isRequired ? (
          label ? (
            <p>
              {label} <b style={{ color: '#c0392b' }}>*</b>
            </p>
          ) : null
        ) : label ? (
          <p>{label}</p>
        ) : null
      }
      name={name}
      rules={rules}
      css={styles}
      changeOnBlur
    >
      <DatePicker
        format={dateFormat || DATE_FORMAT}
        picker={picker}
        disabled={disabled || false}
        placeholder={placeholder || DATE_FORMAT}
        defaultValue={defaultValue}
        changeOnBlur
        // locale={{
        //   ...locale,
        //   lang: {
        //     ...locale.lang,
        //     today: 'Today',
        //   },
        // }}
        {...rest}
      />
    </GroupDatePicker>
  );
});

const GroupDatePicker = styled(Form.Item)<any>`
  width: 100%;
  margin-bottom: 10px;

  label {
    font-size: 14px;
    color: #928c8c;
    font-weight: 400;
    &::after {
      content: ${props => (props.label === ' ' ? '" "' : '":"')} !important;
    }
  }
  .ant-form-item-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 4px;
    label {
      font-size: 14px;
      color: #928c8c;
      font-weight: 400;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${props => props.theme.color_primary};
    border-color: ${props => props.theme.color_primary};
  }
  .ant-picker-disabled {
    background-color: var(--color-gray-4) !important;
  }
  .ant-picker {
    width: 100%;
    padding: 6px 20px 6px 22px !important;
    border-radius: 10px !important;
    border-color: #5f5858 !important;
    min-height: 44px !important;
    height: max-content !important;
    background: #494443 !important;

    &:hover {
      border-color: #eb7d17 !important;
    }

    &:focus {
      border-color: #eb7d17 !important;
      box-shadow: 0px 0px 14px 0px #ff00001a;
    }

    &::placeholder {
      color: #d1d5d8;
      font-weight: 400;
      font-size: 14px;
    }

    &.inlines {
      border: 1px solid #eb7d17 !important;
      border-radius: 8px;
      background: #fff;
    }
    .ant-picker-suffix {
      color: #928c8c;
    }

    .ant-picker-input > input {
      color: #fff;

      &::placeholder {
        color: #5f5858;
      }
    }

    .ant-picker-clear {
      color: #fff;
      background: #494443;
    }
  }
`;
