import React, { useState, useEffect, useRef } from 'react';
import { setFullscreen, exitFullscreen } from 'utils/utils';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import StreamBar from './StreamBar';
import { breakpoints } from 'utils/media-breakpoint';
import { _log } from 'utils/_log';
import mqtt, { MqttClient } from 'mqtt';
import { pixelateArea } from 'utils/maskCanvas';
import { IS_IPHONE } from 'utils/constants';

const fullscreenchangeList = [
  'fullscreenchange',
  'webkitfullscreenchange',
  'mozfullscreenchange',
  'MSFullscreenChange',
];
const fullscreenerrorList = [
  'fullscreenerror',
  'webkitfullscreenerror',
  'mozfullscreenerror',
  'MSFullscreenError',
];

const Stream = props => {
  const [statusInit, setStatusInit] = useState(false);
  const { full, setFull, setIdVideo } = props;
  const [config, setConfig] = useState(() => ({ ...props.config }));
  const refItem = useRef<any>();
  const refCanvas = useRef<HTMLCanvasElement | null>(null);
  const refCanvasTemp = useRef<HTMLCanvasElement | null>(null);
  const animationFrameId = useRef<number | null>(null);
  const refData = useRef<number[][]>();
  const idInterval = useRef<NodeJS.Timeout>();

  useEffect(() => {
    fullscreenchangeList.forEach(item => {
      document.addEventListener(item, () => {
        if (document.fullscreenElement) {
          setFull(true);
        } else {
          setFull(false);
        }
      });
    });
    fullscreenerrorList.forEach(item => {
      document.addEventListener(item, () => {
        toast.error('フルスクリーンの設定がエラーです');
      });
    });
    return () => {
      [...fullscreenchangeList, ...fullscreenerrorList].forEach(item => {
        document.removeEventListener(item, () => {});
      });
    };
  }, []);

  const handleState = (handle, value) => {
    handle(prevValue => ({ ...prevValue, ...value }));
  };

  const handleChange = e => {
    if (e.name === 'full') {
      if (!full) {
        setFullscreen(refItem.current);
      } else {
        exitFullscreen();
      }
      return;
    }
    if (e.name === 'picture') {
      try {
        if ('pictureInPictureEnabled' in document) {
          const isInPicture = document.pictureInPictureElement;
          if (!isInPicture) {
            refItem.current.childNodes[1].childNodes[1].requestPictureInPicture();
          } else {
            document.exitPictureInPicture();
          }
        } else {
          toast.error(
            'ブラウザはピクチャーインピクチャーをサポートしていません',
          );
        }
      } catch (error) {
        _log('Stream error: ', error, 'error');
      }
      return;
    }
    props.onChange && props.onChange(e);
  };

  const handleResume = () => {
    props.onChange &&
      props.onChange({
        name: 'resumeFlag',
        stream: config.stream,
      });
  };

  useEffect(() => {
    if (props.init && !statusInit) {
      props.init(refItem.current);
      const current = {
        userID: props.stream.getUserId(),
        type: props.stream.getType(),
      };
      handleState(setConfig, current);
      setStatusInit(true);
    }
    handleState(setConfig, { ...props.config });
  }, [props.type, props.init, props.stream, props, statusInit]);

  const handleMaskVideo = videoId => {
    if (refCanvas.current) {
      const videoOrigin: HTMLVideoElement | null = document.getElementById(
        videoId,
      ) as HTMLVideoElement;
      if (!videoOrigin) {
        if (!props.isMySelf) {
          refItem.current.childNodes[1].innerHTML = '';
        }
        return;
      }

      const client: MqttClient = mqtt.connect(
        String(process.env.REACT_APP_MQTT_URL),
        {
          username: String(process.env.REACT_APP_MQTT_USERNAME),
          password: String(process.env.REACT_APP_MQTT_PASSWORD),
        },
      );

      client.on('connect', () => {
        // Subscribe to a topic
        client.subscribe('playbackStreaming-' + props.roomID);
      });

      videoOrigin.onloadeddata = () => {
        if (!refCanvas.current || !refCanvasTemp.current) return;

        const h = videoOrigin.videoHeight;
        const w = videoOrigin.videoWidth;
        videoOrigin.style.display = 'none';

        // update size canvas
        refCanvas.current.height = h;
        refCanvas.current.width = w;
        refCanvasTemp.current.height = h;
        refCanvasTemp.current.width = w;

        const videoElement: HTMLVideoElement = document.createElement('video');

        const ctx = refCanvas.current.getContext('2d', {
          willReadFrequently: true,
        });
        const ctxTemp = refCanvasTemp.current.getContext('2d', {
          willReadFrequently: true,
        });
        if (!ctx || !ctxTemp) return;

        const drawLoop = () => {
          ctx.drawImage(videoOrigin, 0, 0, w, h);

          if (refData.current && refData.current?.length > 0) {
            for (const obj of refData.current) {
              const x = obj[0] - 20;
              const y = obj[1] - 20;
              const w = obj[2] + 40;
              const h = obj[3] + 40;

              pixelateArea(ctx, x, y, w, h, 15);
            }
          }
          animationFrameId.current = requestAnimationFrame(drawLoop);
        };
        drawLoop();

        // Start MQTT
        const detectImage = () => {
          if (!refCanvasTemp.current) return;
          ctxTemp.drawImage(videoOrigin, 0, 0, w, h);

          const image = refCanvasTemp.current
            .toDataURL('image/png')
            ?.replace('data:image/png;base64,', '');

          if (image) {
            client.publish(
              'streaming',
              `roomID=${props.roomID}&file=${image}`,
              {
                properties: { contentType: 'json' },
              },
            );
          }
        };

        props.isMySelf && detectImage();

        client.on('message', (_, message) => {
          const strMessage = new TextDecoder().decode(message);
          refData.current = JSON.parse(strMessage);
          props.isMySelf && detectImage();
        });
        // End MQTT

        const stream = refCanvas.current.captureStream(30);
        videoElement.srcObject = stream;
        videoElement.muted = true;
        videoElement.autoplay = true;
        videoElement.playsInline = true;
        videoElement.width = w;
        videoElement.height = h;

        if (refItem.current.childNodes[1]) {
          refItem.current.childNodes[1]?.appendChild(videoElement);
        }
      };
    }
  };

  const handleMaskCanvas = () => {
    if (refCanvas.current) {
      const videoOrigin: HTMLCanvasElement | null =
        refItem.current.childNodes[1].getElementsByTagName(
          'canvas',
        )[0] as HTMLCanvasElement;

      if (!videoOrigin) {
        if (!props.isMySelf) {
          refItem.current.childNodes[1].innerHTML = '';
        }
        return;
      }

      const client: MqttClient = mqtt.connect(
        String(process.env.REACT_APP_MQTT_URL),
        {
          username: String(process.env.REACT_APP_MQTT_USERNAME),
          password: String(process.env.REACT_APP_MQTT_PASSWORD),
        },
      );

      client.on('connect', () => {
        // Subscribe to a topic
        client.subscribe('playbackStreaming-' + props.roomID);
      });

      if (!refCanvas.current || !refCanvasTemp.current) return;
      const h = videoOrigin.height;
      const w = videoOrigin.width;

      videoOrigin.style.display = 'none';

      // update size canvas
      refCanvas.current.height = h;
      refCanvas.current.width = w;
      refCanvasTemp.current.height = h;
      refCanvasTemp.current.width = w;

      const ctx = refCanvas.current.getContext('2d', {
        willReadFrequently: true,
      });
      const ctxTemp = refCanvasTemp.current.getContext('2d', {
        willReadFrequently: true,
      });
      if (!ctx || !ctxTemp) return;

      const drawLoop = () => {
        ctx.drawImage(videoOrigin, 0, 0, w, h);

        if (refData.current && refData.current?.length > 0) {
          for (const obj of refData.current) {
            const x = obj[0] - 20;
            const y = obj[1] - 20;
            const w = obj[2] + 40;
            const h = obj[3] + 40;

            pixelateArea(ctx, x, y, w, h, 15);
          }
        }
        animationFrameId.current = requestAnimationFrame(drawLoop);
      };
      drawLoop();

      // Start MQTT
      const detectImage = () => {
        if (!refCanvasTemp.current) return;
        ctxTemp.drawImage(videoOrigin, 0, 0, w, h);

        const image = refCanvasTemp.current
          .toDataURL('image/png')
          ?.replace('data:image/png;base64,', '');

        if (image) {
          client.publish('streaming', `roomID=${props.roomID}&file=${image}`, {
            properties: { contentType: 'json' },
          });
        }
      };

      props.isMySelf && detectImage();

      client.on('message', (_, message) => {
        const strMessage = new TextDecoder().decode(message);
        refData.current = JSON.parse(strMessage);
        props.isMySelf && detectImage();
      });
      // End MQTT
    }
  };

  const getVideoAndAudioId = () => {
    let videoId = '';
    let audioId = '';
    const element = refItem.current.childNodes[1].childNodes;

    for (let i = 0; i < element.length; i++) {
      if (element[i]?.id.includes('video')) {
        videoId = element[i]?.id;
      }
      if (element[i]?.id.includes('audio')) {
        audioId = element[i]?.id;
      }
    }

    return { videoId, audioId };
  };

  useEffect(() => {
    const { videoId, audioId } = getVideoAndAudioId();
    const divId = refItem.current.childNodes[1]?.id;
    setIdVideo &&
      setIdVideo({
        divId,
        audioId,
        videoId,
      });
  }, []);

  useEffect(() => {
    const { videoId } = getVideoAndAudioId();
    if (config.userID) {
      if (videoId) {
        handleMaskVideo(videoId);
      }
      if (!videoId && IS_IPHONE) {
        handleMaskCanvas();
      }
    }

    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
      if (idInterval.current) {
        clearInterval(idInterval.current);
      }
    };
  }, [config.userID, props.isMuteVideo]);

  return (
    <VideoContain
      className={`${props.className}`}
      isMySelf={props.isMySelf}
      isTheater={props.isTheater}
      isFull={full}
      isMobileLive={props.isMobileLive}
      breakpoints={breakpoints}
    >
      <div
        id="viewer"
        ref={refItem}
        style={{
          width: '100%',
          height: `${props.isTheater ? 'unset' : '100%'}`,
          aspectRatio: `${props.isTheater ? '16/9' : 'unset'}`,
          position: 'relative',
        }}
      >
        <StreamBar
          config={config}
          onChange={handleChange}
          setting={props.setting}
          isMySelf={props.isMySelf}
          full={full}
          setFull={setFull}
          isTheater={props.isTheater}
          localStreamConfig={props.localStreamConfig}
          remoteStreamConfigList={props.remoteStreamConfigList}
          userStream={props.userStream}
          handleLike={props.handleLike}
          liked={props.liked}
          setOpenModalDonation={props.setOpenModalDonation}
          openModalDonation={props.openModalDonation}
          roomID={props.roomID}
          currentUser={props.currentUser}
          formatTime={props.formatTime}
          time={props.time}
          totalOfLike={props.totalOfLike}
          totalOfViewer={props.totalOfViewer}
          messageLists={props.messageLists}
          isClientBannedError={props.isClientBannedError}
          isMultipleRoomsError={props.isMultipleRoomsError}
        />
      </div>
      <canvas
        ref={refCanvas}
        style={
          IS_IPHONE
            ? {
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }
            : { display: 'none' }
        }
      ></canvas>
      <canvas ref={refCanvasTemp} style={{ display: 'none' }}></canvas>
      {config.resumeFlag && <div onClick={handleResume}>play icon</div>}
    </VideoContain>
  );
};

export default React.memo(Stream);

const VideoContain = styled.div<any>`
  width: 100%;
  height: 100%;

  > div > div:first-child {
    width: 100%;
    height: 100%;
    /* background-color: transparent !important; */
    background-color: #000000 !important;

    border-radius: ${props => (props.isFull ? '0' : '20px')};

    @media only screen and (min-width: ${props => props.breakpoints.tablet}) {
      border-radius: ${props =>
        props.isTheater || props?.isFull
          ? '0'
          : props?.isMySelf
          ? '0'
          : '20px'};
    }
  }

  > div > div:nth-child(2) {
    /* width: 100%; */
    overflow: hidden;
    width: ${props => (props.isMobileLive ? 'unset' : '100%')} !important ;
    height: ${props =>
      props.isMySelf
        ? '100%'
        : props.isMobileLive
        ? '100%'
        : 'unset'} !important;
    aspect-ratio: ${props =>
      props.isMySelf
        ? 'unset'
        : props.isMobileLive
        ? '12/16'
        : '16/9'} !important;
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent !important;

    @media only screen and (min-width: ${props => props.breakpoints.tablet}) {
      width: ${props => (props.isMobileLive ? 'unset' : '100%')} !important ;
      height: ${props =>
        props.isMySelf || props.isFull
          ? '100%'
          : props.isTheater
          ? props.isMobileLive
            ? '100%'
            : 'unset'
          : '100%'} !important;
      aspect-ratio: ${props =>
        props.isMySelf
          ? 'unset'
          : props.isMobileLive
          ? '12/16'
          : '16/9'} !important;
    }
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${props => (props.isMobileLive ? '25px' : 0)};

    @media only screen and (min-width: ${props => props.breakpoints.tablet}) {
      border-radius: ${props =>
        props.isTheater || props?.isFull || props.isMobileLive
          ? '0'
          : props?.isMySelf
          ? '0'
          : '20px'};
    }
  }
`;
