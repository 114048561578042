import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Form,
  Image,
  Tag,
  Typography,
  Upload,
  UploadProps,
} from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';

import { CameraIcon, EditIcon, ProfileBanner } from 'styles/image';
import {
  MetaButton,
  MetaInput,
  MetaSelect,
  MetaTextArea,
} from 'app/components/Forms';
import {
  getBase64,
  getFirstLetter,
  regexURL,
  renderOptionId,
  regexIcon,
  regexSpecialCharacter,
} from 'utils/helpers';
import { ACCEPT_IMAGE } from 'app/constants';
import { MetaHelmet } from 'app/components/MetaHelmet';
import { useProfileSlice } from '../slice';
import { selectProfile } from '../slice/selectors';
import { selectAuth } from 'app/pages/AuthPage/slice/selectors';
import { updateUserRequest } from 'app/apis/profile';
import { actionsToast } from 'app/pages/ToastPage/slice';
import { MetaDatePicker } from 'app/components/Forms/MetaDatePicker';
import dayjs from 'dayjs';
import { PATH } from 'app/constants/path';
import { MetaLoading } from 'app/components';
import { uploadImageRequest } from 'app/apis/images';
import { toast } from 'react-toastify';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/es/upload';

export function ProfileEdit() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const isMobile = useMediaQuery({ maxWidth: '703px' });
  const { actions } = useProfileSlice();
  const { currentUser } = useSelector(selectAuth);
  const { labels } = useSelector(selectProfile);
  const [avatarUrl, setAvatarUrl] = useState<any>('');
  const [isEditName, setIsEditName] = useState(false);
  const [userName, setUserName] = useState<any>(null);
  const [url, setUrl] = useState<any>(null);
  const [phoneNumber, setPhoneNumber] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fileUpload, setFileUpload] = useState<File | null>();

  const tagRender = props => {
    const { label, value, closable, onClose } = props;
    let textColor = '';
    let bgColor = '';

    switch (value) {
      case 'Tag 1':
        textColor = '#F5A406';
        bgColor = '#FFECA9';
        break;
      case 'Excepteur':
        textColor = '#2086CF';
        bgColor = '#A9DBFF';
        break;
      case 'dolor in reprehenderit':
        textColor = '#52AD32';
        bgColor = '#CFFFA9';
        break;
      case 'qui officia':
        textColor = '#EB7D17';
        bgColor = '#FFBCB2';
        break;
    }

    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          background: bgColor,
          borderColor: textColor,
          color: textColor,
          marginRight: '10px',
        }}
      >
        {label}
      </Tag>
    );
  };

  const PopupRender = () => (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '20px',
        width: '100%',
      }}
    >
      <MetaButton
        className="btn-save btn-primary-blue"
        type="primary"
        text="スパチャLiveへ"
        onClick={() => {
          navigate(PATH.LIST_STREAM);
          dispatch(actionsToast.closeToast({}));
        }}
        style={{
          height: '52px',
          width: '100%',
        }}
      />
    </div>
  );

  const onFinish = async values => {
    setIsLoading(true);
    try {
      const type = 'avatar';
      values.username = userName;
      values.url = url;
      values.phone_number = phoneNumber;

      const formData = form.getFieldsValue(true);

      let imageUrl: string = formData.avatar_url;

      if (fileUpload) {
        const result = await uploadImageRequest(fileUpload, type);

        imageUrl = result.data.url;
        values.avatar_url = imageUrl;
      }

      const res: any = await updateUserRequest({
        ...values,
        dob: values?.dob || null,
      });
      if (res?.statusCode === 200) {
        setIsLoading(false);
        dispatch(actions.updateUserSuccess(res));

        if (currentUser?.firstLogin) {
          dispatch(
            actionsToast.openToast({
              titleCustom: ' ',
              message: ` `,
              type: 'customChild',
              children: <PopupRender />,
            }),
          );
        } else {
          navigate(PATH.PROFILE);
        }
      }
    } catch (err: any) {
      setIsLoading(false);
      dispatch(
        actionsToast.openToast({
          message: err?.response?.data?.message,
          type: 'error',
        }),
      );
    }
  };

  const handleUpload: UploadProps['onChange'] = async (
    info: UploadChangeParam<UploadFile>,
  ) => {
    const { file } = info;

    if (file) {
      const isValid = (file.size as number) / 1024 / 1024 <= 20;

      if (!isValid) {
        toast.error(
          'Please upload an image be in the format (.jpg,.jpeg,.png) and less than 20 MB.',
        );

        return;
      }

      const originFile = file?.originFileObj;

      setFileUpload(originFile);

      const base64 = await getBase64(originFile as RcFile);

      setAvatarUrl(base64);

      form.setFieldsValue({
        avatar_url: base64,
      });
    }
  };

  function disabledDate(current) {
    const today = moment().startOf('day');
    return current && current >= today;
  }

  const props: any = {
    className: 'meta-upload',
    accept: ACCEPT_IMAGE,
    fileList: [],
    showUploadList: false,
    onChange: handleUpload,
    customRequest: () => {
      Promise.resolve().then(() => {
        return;
      });
    },
  };

  useEffect(() => {
    dispatch(actions.getLabel({}));
  }, []);

  useEffect(() => {
    if (currentUser) {
      setUserName(currentUser?.username);
      setAvatarUrl(currentUser?.avatar_url);
      form.setFieldsValue({
        ...currentUser,
        labels: currentUser?.labels?.map(ele => ele?.id),
        dob: currentUser?.dob ? dayjs(currentUser?.dob) : '',
      });
    }
  }, [currentUser]);

  if (!currentUser) {
    return <MetaLoading loading />;
  }

  return (
    <Wrapper>
      <MetaHelmet title="FU-CHA" />
      <div className="profile-banner">
        <Image
          src={currentUser?.cover_url ? currentUser?.cover_url : ProfileBanner}
          height={isMobile ? 162 : 304}
          alt="banner"
          preview={false}
        />
      </div>

      <div className="container-custom">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className="form-login"
        >
          <div className="description-box">
            <div className="avatar-box">
              <Avatar shape="square" src={avatarUrl}>
                {getFirstLetter(currentUser?.username || '')}
              </Avatar>
              <Upload {...props}>
                <Image
                  className="upload-camera-icon"
                  src={CameraIcon}
                  width={32}
                  height={32}
                  alt="camera"
                  preview={false}
                />
              </Upload>
            </div>
            <Typography.Title
              level={2}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                justifyContent: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              // onBlur={() => {
              //   setIsEditName(false);
              // }}
            >
              {isEditName ? (
                <div className="custom-input-non-border">
                  <MetaInput
                    name="username"
                    isNormalize
                    maxLength={20}
                    bordered={false}
                    value={userName}
                    onChange={e => {
                      setUserName(e.target.value);
                    }}
                    form={form}
                    rules={[
                      { required: true, message: 'Please enter Username' },
                      {
                        min: 5,
                        message: 'Username must contain from 5 to 20 character',
                      },
                      {
                        pattern: regexIcon,
                        message: 'Invalid character',
                      },
                      {
                        pattern: regexSpecialCharacter,
                        message: 'Invalid character',
                      },
                    ]}
                  />
                </div>
              ) : (
                <div className="user-name__show">
                  <div className="edit-user-name">
                    <p className="ellipsis-1">{userName}</p>
                    <Image
                      className="upload-camera-icon"
                      src={EditIcon}
                      width={18}
                      height={18}
                      alt="edit-icon"
                      preview={false}
                      onClick={() => setIsEditName(!isEditName)}
                      style={{
                        marginLeft: '6px',
                        marginTop: '5px',
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                  <p className="use-name__notify">
                    ユーザー名を変更する場合は次の変更ができるのは一週間後です
                  </p>
                </div>
              )}
            </Typography.Title>
          </div>

          <MetaInput
            name="phone_number"
            label="電話番号"
            placeholder="電話番号を入力してください"
            form={form}
            type="number"
            onChange={e => {
              setPhoneNumber(e.target.value);
            }}
            rules={
              phoneNumber && [
                {
                  max: 20,
                  message: 'Please enter no more than 20 characters',
                },
                {
                  pattern: regexIcon,
                  message: 'Invalid character',
                },
              ]
            }
          />

          <MetaInput
            name="email"
            label="メールアドレス"
            placeholder="メールアドレスを入力して"
            disabled
            form={form}
          />

          <MetaInput
            name="url"
            label="Link"
            placeholder="SNSへのリンクを入力してください。"
            value={url}
            onChange={e => {
              setUrl(e.target.value);
            }}
            rules={
              url && [
                {
                  pattern: regexURL,
                  message: 'Please enter valid URL',
                },
                {
                  pattern: regexIcon,
                  message: 'Invalid character',
                },
              ]
            }
            form={form}
          />

          <MetaDatePicker
            name="dob"
            label="誕生日"
            disabledDate={disabledDate}
          />

          <MetaSelect
            name="labels"
            mode="multiple"
            tagRender={tagRender}
            value={{
              value: 'jack',
              label: 'Jack',
            }}
            options={renderOptionId(labels)}
            placeholder="興味のあるカテゴリ"
            label="興味のあるカテゴリ"
          />

          <MetaTextArea
            name="bio"
            label="自己紹介"
            placeholder="自己紹介"
            rows={8}
            form={form}
            maxLength={255}
          />

          <div
            style={{
              display: 'grid',
              gap: '20px',
              gridTemplateColumns: '1fr',
            }}
          >
            <Form.Item>
              <MetaButton
                className="btn-save btn-primary-pink"
                type="primary"
                htmlType="submit"
                is_loading={isLoading}
                text="保存する"
              />
            </Form.Item>
          </div>
        </Form>
      </div>
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .ant-image,
  .ant-image-mask,
  .ant-image-mask:hover,
  .ant-image img {
    object-fit: cover;
    border-radius: 0px 0px 20px 20px;
  }

  .ant-avatar {
    background: #ff347d;
    font-size: 60px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
    border-radius: 20px;
    margin: auto;
    @media only screen and (max-width: 1024px) {
      width: 120px;
      height: 120px;
    }
  }

  .ant-avatar-image {
    background: transparent;
  }

  .profile-banner {
    position: relative;
    height: 304px;
    @media only screen and (max-width: 1024px) {
      height: 162px;
    }
  }

  .description-box {
    text-align: center;
    margin-top: -90px;
    margin-bottom: 40px;
    @media only screen and (max-width: 1024px) {
      margin-top: -60px;
      margin-bottom: 0px;
    }
    h2 {
      font-size: 34px;
      font-weight: 700;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .avatar-box {
      position: relative;
      width: 180px;
      height: 180px;
      margin: auto;
      @media only screen and (max-width: 1024px) {
        width: 120px;
        height: 120px;
      }
      .ant-image:last-child,
      .upload-camera-icon {
        position: absolute;
        bottom: 0px;
        right: 0px;
        background: #fff;
        padding: 7px;
        border-radius: 8px;
        cursor: pointer;
      }
      .ant-image:last-child {
        bottom: 12px;
        right: 8px;
      }
    }
  }

  .edit-user-name {
    display: flex;
    align-items: center;
    color: #fff;
    p {
      margin: 0;
      @media only screen and (max-width: 390px) {
        width: 90%;
      }
    }
  }

  .user-name__show {
    display: flex;
    flex-direction: column;
    align-items: center;
    .use-name__notify {
      font-size: 11px;
      margin: 0;
      margin-top: 5px;
      color: #e10000;
    }
  }

  .ant-form {
    max-width: 628px;
    margin: auto;
  }

  .custom-input-non-border {
    input {
      font-size: 26px;
      font-weight: bold;
      text-align: center;

      background: #ccdef9;
      height: max-content;
      padding: 0px;
      margin: auto;
    }

    input:focus {
      box-shadow: none;
    }
  }

  .btn-save {
    height: 52px;
    width: 60%;
    margin: auto;
    margin-top: 20px;
    @media only screen and (max-width: 390px) {
      width: 100%;
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
`;
