import React from 'react';
import { Avatar, Col, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import { PATH } from 'app/constants/path';
import { getFirstLetter } from 'utils/helpers';

const FollowedStreamer = ({ followingLists }) => {
  const navigate = useNavigate();

  return (
    <Row gutter={[20, 20]} className="suggestion-box">
      {followingLists?.map((s, i) => (
        <Col xs={12} sm={8} md={6} lg={4} xxl={3} key={i}>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(`${PATH.PROFILE}/${s.target_id}`)}
          >
            <Avatar shape="square" src={s?.target?.avatar_url}>
              {getFirstLetter(s?.target?.username || '')}
            </Avatar>
            <Typography.Title
              level={5}
              className="ellipsis-1"
              style={{ height: '20px', color: '#fff' }}
            >
              {s?.target?.username}
            </Typography.Title>
            <Typography.Text>
              {s?.target?.total_followers || 0}
              <span style={{ marginLeft: 3 }}>
                {s?.target?.total_followers > 1 ? 'followers' : 'follower'}
              </span>
            </Typography.Text>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default FollowedStreamer;

{
  /* <div className="video-container">
          <div className="suggestion-header">
            <Image
              alt="avatar"
              preview={false}
              src={PlayIcon}
              width={14}
              height={14}
            />
            <h5>Video</h5>
            <div className="sort-list">
              {sortIcons?.map((icon, i) => (
                <Image
                  key={i}
                  alt="avatar"
                  preview={false}
                  src={icon}
                  width={18}
                  height={18}
                  onClick={() => setSortKey(i + 1)}
                  style={{
                    opacity: sortKey === i + 1 ? '1' : '0.3',
                  }}
                />
              ))}
            </div>
          </div>
          <Row gutter={[20, 20]} className="suggestion-box">
            {[1, 2, 3, 4, 5, 6, 7, 8, 8, 6, 5, 5]?.map((s, i) => (
              <Col xs={12} sm={8} xl={4} key={s}>
                <div
                  onMouseOver={() => setControlId(i)}
                  onMouseLeave={() => setControlId(null)}
                >
                  <video
                    id="myVideo"
                    height="320"
                    className="controls"
                    loop
                    controls={i === controlId}
                    poster={SuggestionVDO}
                  >
                    <source
                      src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                  <Typography.Title level={5} className="ellipsis-1">
                    Video title
                  </Typography.Title>
                  <Typography.Text>629K viewer</Typography.Text>
                </div>
              </Col>
            ))}
          </Row>
        </div> */
}
